import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './views/home'
import Add_data from './views/add_data'
import Beneficiaries from './views/beneficiaries'
import Financial from './views/financial'
import Thanks from './views/thanks'
import Pro from './views/pruebas'
import Steper from './components/stepper';
import Calculator from './views/calculator'
import Verify from './views/verify';
import NotFound from './views/404';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/add_data" component={Add_data} />
      <Route exact path="/beneficiaries" component={Beneficiaries} />
      <Route exact path="/financial" component={Financial} />
      <Route exact path="/thanks" component={Thanks} />
      <Route exact path="/pruebas" component={Pro} />
      <Route exact path="/stepper" component={Steper} />
      <Route exact path="/calculator" component={Calculator} />
      <Route exact path="/verify_token/:id" component={Verify} />
      <Route component={NotFound} />
    </Switch>
  )
}