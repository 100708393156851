import React, { useState, useContext } from 'react'
import { Context } from '../services/store'
import $ from 'jquery'

import Step1 from '../components/beneficiaries/Step1'
import Step2 from '../components/beneficiaries/Step2'
import Step3 from '../components/beneficiaries/Step3'
import Steper from '../components/stepper'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Beneficiaries({ changeStep }) {

  // eslint-disable-next-line
  const { t } = useTranslation()
  const steps = [1, 2, 3];
  const [step, setStep] = useState(1)
  const [error, setError] = useState(false);
  
  // @ts-ignore
  const [state, dispatch] = useContext(Context);

  const changeStepBene = e => {
    if (e > 0 && e <= 3) {
      let postion = '';
      if (e > 1) { postion = '-' + ((e * 100) - 100) + '%' }
      $('.beneficiaries #button-label-1').removeClass('active')
      $('.beneficiaries #sp-slider li').attr('style', 'opacity:0.4');
      $('.beneficiaries #sp-slider li').removeClass('active');
      $('.beneficiaries #sp-slider li:nth-child(' + e + ')').addClass('active');
      $('.beneficiaries .packbullet:nth-child(' + e + ')').prevAll().find('.button-label').addClass("active");
      $('.beneficiaries .packbullet:nth-child(' + e + ')').nextAll().find('.button-label').removeClass("active");
      $('.beneficiaries #sp-slider').height($('.beneficiaries #sp-slider li:nth-child(' + e + ') .step-content').outerHeight());
      $('.beneficiaries #button-' + e).prop('checked', true);
      $('.beneficiaries #sp-slider li.active').attr('style', 'opacity:1')
      $('.beneficiaries #sp-slider').css('left', postion);
      setStep(e)
    }
    if (e === 4) {
      changeStep(20)
    }
  }

  const dispathInputsBene = (inputs) => {
    Object.keys(inputs).map(function (key) {
      return dispatch({ type: 'ADD_INPUT', payload: { [key]: inputs[key] } });
    });
    // console.log(state.inputs);
  }

  return (
    <div className="content_box beneficiaries view">
      <Steper step={4} />
      <div className="sp-slideshow steps-3">
        <div className="bullets">
          {steps.map((value, index) => {
            return (
              <div className="packbullet" key={index}>
                <input id={'button-' + value} type="radio" name="radio-set" className={'sp-selector'} disabled />
                <label id={'button-label-' + value} htmlFor={'button-' + value} className={index === 0 ? 'button-label active' : 'button-label button-label-' + value}></label>
              </div>
            );
          })}
        </div>
        <div className='sp-arrow sp-al' onClick={() => changeStepBene(step + 1)}>
          <i className="icon-arrow"></i>
        </div>
        <div className='sp-arrow sp-ar' onClick={() => changeStepBene(step - 1)}>
          <i className="icon-arrow"></i>
        </div>
        <div className="sp-content">
          <div className="steps-count">Paso {step} de 3</div>
          <ul id="sp-slider" className="animater clearfix">
            <li className='active'><Step1 dispathInputsBene={dispathInputsBene} changeStep={changeStepBene} setError={setError} /></li>
            <li className=''><Step2 dispathInputsBene={dispathInputsBene} changeStep={changeStepBene} setError={setError}  /></li>
            <li className=''><Step3 dispathInputsBene={dispathInputsBene} changeStep={changeStepBene} setError={setError}  /></li>
          </ul>
        </div>
        <div className="error_general">{error && t('generalerror.label')}</div>
      </div>
    </div>
  )
} 