import React, { useEffect } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Home() {

  const { t, i18n } = useTranslation()

  useEffect(() => {
      setTimeout(() => {
        $('.home .content-hello').attr('style', 'display:flex;'); 
        $('.home .content-hello').addClass('animate__animated animate__bounceIn')
      }, 1000)
  }, [])

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('lng', lang)
    $('.home .content-hello').removeClass('animate__bounceIn')
    $('.home .content-hello').addClass('animate__bounceOut')
    setTimeout(() => {
      $('.home .content-hello').hide();
    }, 500)

  }

  const goSteps = () => {
    $('.home .contentHome').addClass('animate__animated animate__backOutLeft')
    $('.home #sp-slider').attr('style', 'display:flex;'); 
    $('.home .sp-slideshow').attr('style', 'opacity:0;'); 
    $('.home #sp-slider').height($('.home #sp-slider li:nth-child(1) .step-content').outerHeight())
    window.analytics.track("Requests Status", {
      status: 'Initial',
      attempt_id: Date.now()
    });
    setTimeout(() => {
      $('.steps-count').show();
      $('.home .contentHome').hide();
      $('.home .sp-slideshow').attr('style', 'opacity:1;'); 
      $('.home #sp-slider').addClass('animater')
      $('.home #sp-slider').addClass('animate__animated animate__fadeIn')
      setTimeout(() => {
        $('.home .bullets').attr('style', 'display:flex;');
        $('.home .bullets').addClass('animate__animated animate__backInDown')
      }, 10)
    }, 50)
  } 

  return (
    <div className ='contentHome'>
      <div className='stepHome'>
        <div className='divHome'>
          <div className='divHome-right'>
            <h2 className={'saludo '+ t('lang.label')} > {t('cuidamos.label')}</h2>
            <h2 className={'saludo a2 '+ t('lang.label')} ><span> {t('tufamilia.label')} </span></h2>
            <button className='button button-stepHome' onClick={goSteps}>
              <span>{t('cotiza.label')}</span><i className="icon-arrow"></i>
            </button>
          </div>
        </div>
      </div>
      <div className='content-hello'>
        <div className='hello'>
          <div className='imagen'>
          </div>
          <div className='texto'>
            <h3>{t('hola.label')}</h3>
            <p>{t('antes.label')}</p>
            <div className='botones'>
              <button className='bt usa' onClick={() => changeLanguage('en')} type='button'></button>
              <button className='bt esp' onClick={() => changeLanguage('es')} type='button'></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
