import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../services/store'
import moment from 'moment';

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'
import { getinApi } from '../services/api';
import { useParams } from 'react-router-dom';


export default function Verify({ dispathInputs, changeStep, calcu }) {

  const { t } = useTranslation()
  let {id} = useParams();
  let [states, setStates] = useState([])

  useEffect(() => {
    getVerify()
  }, [])

  
  const getVerify = async () => {
    await getinApi('get', 'verify/' + id)
      .then((res) => {
        console.log(res);
        window.location.href = "https://www.emilan.co/";
      })
      .catch((err) => console.log(err))
  }
  
  return (
    <div className="">
      Verificando .... 
      Completo...
      Redireccionando....
    </div>
  )
}


