import React, { useState, useEffect } from 'react'
import $ from 'jquery'

// Services
import { getinApi } from '../services/api'
import Select from 'react-select'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Step2({ dispathInputs, changeStep, setError}) {

    // eslint-disable-next-line
  const { t } = useTranslation()

  let [states, setStates] = useState([])
  let [cities, setCities] = useState([])
  let [selectcity, setSelCity] = useState()
  let [selectstate, setSelState] = useState()

  useEffect(() => {
    getStates()
  }, [])

  const [inputs, setInputs] = useState({
    addres: '',
    state: '',
    city_id: '',
    zip: 0
  })

  const setSelectState = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    setSelState(e)
    setSelCity(null)
    getCities(e.value)
    setInputs({ ...inputs, state: e.value, city_id: ''})
  }

  const setSelectCity = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    setSelCity(e)
    setInputs({ ...inputs, city_id: e.value})
  }

  const setInput = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }


  const submitInputs = () => {
    let validations = 0;
    if ((inputs.addres).trim() === '') {
      $('.inp-direccion').addClass('invalide')
      validations = 1;
    }
    if ((inputs.state).trim() === '') {
      $('#inp-state').addClass('invalide')
      validations = 1;
    }

    if ((String(inputs.city_id)).trim() === '') {
      $('#inp-ciudad').addClass('invalide')
      validations = 1;
    }
    if ((inputs.zip) === 0 || (inputs.zip).length < 3 || isNaN(inputs.zip) || (inputs.zip).length === 0) {
      $('.inp-zip').addClass('invalide')
      validations = 1;
    }

    if (validations) {
      setError(true)
      return
    }

    dispathInputs(inputs)
    changeStep(3)
  } 

  const getStates = async () => {
    await getinApi('get', 'states')
      .then((res) => {
        let states =[];
        res.states.forEach(state => {
          let stat = {
            value: state.state_code,
            label: state.state
          }
          states.push(stat);
        });
        setStates(states)
      })
      .catch((err) => console.log(err))
  }
  const getCities = async (id_state) => {
    await getinApi('get', `states/${ id_state }/cities`)
      .then((res) => {
        let cities = [];
        res.cities.forEach(city => {
          let cit = {
            value: city.id,
            label: city.name
          }
          cities.push(cit);
        });
        setCities(cities)
      })
      .catch((err) => console.log(err))
  }

  return (
    <div className="step step-content step-2">
      <div className="backimage img2">
        <p className='center'>{t('okahora.label')}</p>
        <div className='img'>
          <img className='center' src={"images/img-step2.svg"} alt="img-step2" />
        </div>
      </div>
      <h2 className='center h2'>{t('dondevive.label')}</h2>
      <div className='flx form-st2 form_'>
        <input className='input inp-direccion' type='text' name='addres' placeholder={t('direccion.label')} onChange={setInput} />
        <Select className='inp-ciudad slect-tag' id="inp-state" classNamePrefix="react-select" options={states} value={selectstate} placeholder={t('estado.label')} onChange={setSelectState}/>
        <Select className='inp-ciudad slect-tag' id="inp-ciudad" classNamePrefix="react-select" options={cities} value={selectcity} placeholder={t('ciudad.label')} onChange={setSelectCity}/>
        <input className=' input inp-zip' type='text' name='zip' placeholder={t('zip.label')} onChange={setInput} />
      </div>
      <button className='boton' onClick={submitInputs} type='submit' value='continuar'>{t('continuar.label')}</button>
    </div>
  )
}