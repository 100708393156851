import React, { useState, useContext } from 'react'
import { Context } from '../services/store'
import $ from 'jquery'

import StepHome from '../components/Home'
import Step1 from '../components/Step1'
import Step2 from '../components/Step2'
import Step3 from '../components/Step3'
import Step4 from '../components/Step4'
import Step5 from '../components/Step5'
import Step6 from '../components/Step6'
import Step7 from '../components/Step7'
import Step8 from '../components/Step8'
import Step9 from '../components/Step9'
import Step10 from '../components/Step10'
import Step11 from '../components/Step11'
import Step12 from '../components/Step12'
import Step13 from '../components/Step13'
import Step14 from '../components/Step14'
import Step15 from '../components/Step15'
import Step16 from '../components/Step16'

// views 
import Calculator from '../views/calculator'
import Addata from '../views/add_data'
import Beneficiaries from '../views/beneficiaries'
import Financial from '../views/financial'
import Thanks from '../views/thanks'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'


export default function Home() {

  // eslint-disable-next-line
  const { t } = useTranslation()
  const steps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
  const [step, setStep] = useState(1)
  const [error, setError] = useState(false);
  // @ts-ignore
  const [state, dispatch] = useContext(Context);
  const [ready, setReady] = useState(false);
  const [lang, setLang] = useState(false);
  const [calcu, setCalc] = useState(false);

  const changeStepHome = e => {
  
    if(e > 0 && e <= 16 ){
      getLang()
      let postion = '';
      if (e > 1) { postion = '-' + ((e * 100) - 100 ) + '%' }
      $('.home #button-label-1').removeClass('active')
      $('.home #sp-slider li').attr('style', 'opacity:0.4');
      $('.home #sp-slider li').removeClass('active');
      $('.home #sp-slider li:nth-child(' + e + ')').addClass('active');
      $('.home .packbullet:nth-child('+ e +')').prevAll().find('.button-label').addClass("active");
      $('.home .packbullet:nth-child('+ e +')').nextAll().find('.button-label').removeClass("active");
      $('.home #sp-slider').height($('.home #sp-slider li:nth-child(' + e + ') .step-content').outerHeight());
      $('.home #button-' + e).prop('checked', true);
      $('.home #sp-slider li.active').attr('style', 'opacity:1')
      $('.home #sp-slider').css('left' , postion);
      setStep(e)
      setError(false)
        window.analytics.track("Inside Step", {
          Section: 'Home',
          Step: 'Step - '+e,
          attempt_id: Date.now()
        });
    }

    if((e > 16 && e < 22) || (e === 0) ){
      $('.view').attr('style', 'display:none;');
      $('.view').addClass('animate__animated');
      switch (e) {
        case 17: 
            $('.calculator').attr('style', 'display:block'); 
            $('.calculator').addClass('animate__slideInRight'); 
            window.analytics.track("Inside Step", {
              Section: 'Calculator',
              attempt_id: Date.now()
            });
          break;
        case 18: 
          $('.add_data').attr('style', 'display:block'); 
          $('.add_data').addClass('animate__slideInRight');
          window.analytics.track("Inside Step", {
            Section: 'Add Data',
            attempt_id: Date.now()
          });
          break;
        case 19: 
          $('.beneficiaries').attr('style', 'display:block'); 
          $('.beneficiaries').addClass('animate__slideInRight');
          window.analytics.track("Inside Step", {
            Section: 'Benefeciaries',
            attempt_id: Date.now()
          });
          break;
        case 20: 
          $('.financiala').attr('style', 'display:block'); 
          $('.financiala').addClass('animate__slideInRight');
          window.analytics.track("Inside Step", {
            Section: 'Financial',
            attempt_id: Date.now()
          });
          break;
        case 21: 
          $('.thanks').attr('style', 'display:block'); 
          $('.thanks').addClass('animate__slideInRight');
          window.analytics.track("Inside Step", {
            Section: 'Thanks',
            attempt_id: Date.now()
          });
          break;
        case 0: 
          $('.home').attr('style', 'display:block'); 
          $('.home').addClass('animate__slideInRight');
          window.analytics.track("Inside Step", {
            Section: 'Home',
            attempt_id: Date.now()
          });
          break;
        default:  break;
      }
    }
  }

  const dispathInputs = (inputs) => {
    Object.keys(inputs).map(function (key) {
      return dispatch({ type: 'ADD_INPUT', payload: {[key]: inputs[key]} });
    });
    // console.log(state.inputs);
  }

  const ChangeReady = ()=>{
    setReady(true);
  }
  const getCalc = ()=>{
    setCalc(true);
  }
  const getLang = ()=>{
    setLang(true);
  }

  return (
    <div>
      <div className="home view">
        <div className="sp-slideshow">
          <div className="bullets">
            {steps.map((value, index) => {
              return (
                <div className="packbullet" key={index}>
                  <input id={'button-' + value} type="radio" name="radio-set" className={'sp-selector'} disabled />
                  <label id={'button-label-' + value} htmlFor={'button-' + value} className={index === 0 ? 'button-label active' : 'button-label button-label-' + value}></label>
                </div>
              );
            })}
          </div> 
          <div className='sp-arrow sp-al' onClick={() => changeStepHome(step+1)}>
            <i className="icon-arrow"></i>
          </div>
          <div className='sp-arrow sp-ar' onClick={() => changeStepHome(step-1)}>
            <i className="icon-arrow"></i>
          </div>
          <div className="sp-content">
            <div className="steps-count">{t('stepsof1.label')} {step} {t('stepsof2.label')} 16</div>
            <StepHome />
            <ul id="sp-slider" className="clearfix">
              <li className='active'><Step1 dispathInputs={dispathInputs} changeStep={changeStepHome} setError={setError}/></li>
              <li className=''><Step2 dispathInputs={dispathInputs} changeStep={changeStepHome} setError={setError} /></li>
              <li className=''><Step3 dispathInputs={dispathInputs} changeStep={changeStepHome} setError={setError}/></li>
              <li className=''><Step4 dispathInputs={dispathInputs} changeStep={changeStepHome} setError={setError}/></li>
              <li className=''><Step5 dispathInputs={dispathInputs} changeStep={changeStepHome} setError={setError}/></li>
              <li className=''><Step6 dispathInputs={dispathInputs} changeStep={changeStepHome} setError={setError}/></li>
              <li className=''><Step7 dispathInputs={dispathInputs} changeStep={changeStepHome} setError={setError}/></li>
              <li className=''><Step8 dispathInputs={dispathInputs} changeStep={changeStepHome} setError={setError}/></li>
              <li className=''><Step9 dispathInputs={dispathInputs} changeStep={changeStepHome} lang={lang} setError={setError}/></li>
              <li className=''><Step10 dispathInputs={dispathInputs} changeStep={changeStepHome} lang={lang} setError={setError} /></li>
              <li className=''><Step11 dispathInputs={dispathInputs} changeStep={changeStepHome} setError={setError}/></li>
              <li className=''><Step12 dispathInputs={dispathInputs} changeStep={changeStepHome} setError={setError}/></li>
              <li className=''><Step13 dispathInputs={dispathInputs} changeStep={changeStepHome} setError={setError}/></li>
              <li className=''><Step14 dispathInputs={dispathInputs} changeStep={changeStepHome} setError={setError}/></li>
              <li className=''><Step15 dispathInputs={dispathInputs} changeStep={changeStepHome} getCalc={getCalc} setError={setError}/></li>
              <li className=''><Step16 dispathInputs={dispathInputs} changeStep={changeStepHome} setError={setError}/></li>
            </ul>
          </div>
          <div className="error_general">{error && t('generalerror.label')}</div>
        </div>
      </div>
      <Calculator dispathInputs={dispathInputs} changeStep={changeStepHome} calcu={calcu} />
      <Addata changeStep={changeStepHome} />
      <Beneficiaries changeStep={changeStepHome} />
      <Financial dispathInputs={dispathInputs} changeStep={changeStepHome} ChangeReady={ChangeReady} />
      <Thanks changeStep={changeStepHome} ready={ready} />
    </div>
  )
} 