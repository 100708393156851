import React, { useState, useEffect } from 'react'
import $ from 'jquery'

// Services
import { getinApi } from '../../services/api'
import Select from 'react-select'

// Trasnlation 
import './../../i18n'
import { useTranslation } from 'react-i18next'

export default function Step3({ dispathInputsAdd, changeStep, setError }) {

  // eslint-disable-next-line
  const { t } = useTranslation()

  let [states, setStates] = useState([])
  let [selectstate, setSelState] = useState()
  const [licen, setLice] = useState(false)
  const [liceNo, setLiceno] = useState(false)
  const [inputs, setInputs] = useState({
    lic_active: '',
    lic_state: '',
    nro_license: '',
  })

  const setSelectState = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    setSelState(e)
    setInputs({ ...inputs, lic_state: e.value })
  }

  const setInput = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    if (e.target.name !== 'lactive'){
      setInputs({ ...inputs, [e.target.name]: e.target.value })
    }

    if (e.target.name === 'lactive' && e.target.value === '0'){
      $('.motivos').attr('style', 'display:block;');
      $('.licencia').attr('style', 'display:none;'); 
      setInputs({ ...inputs, lic_active: e.target.value })
      setLiceno(true)
      setLice(false)
      changeStep(3)
    }
    if (e.target.name === 'lactive' && e.target.value === '1'){
      setLice(true)
      setLiceno(false)
      $('.motivos').attr('style', 'display:none;');
      $('.licencia').attr('style', 'display:block;'); 
      changeStep(3)
    }
  }
  const submitInputs = () => {
    let validations = 0;

    if (licen){
      if ((inputs.lic_state).trim() === '') {
        $('.inp-satatus').addClass('invalide')
        validations = 1;
      }
      if ((inputs.nro_license).trim() === '') {
        $('#mi-licencia').addClass('invalide')
        validations = 1;
      }
      if ((inputs.lic_active).trim() === '') {
        $('.inp-active').addClass('invalide')
        validations = 1;
      }
    }

    if (liceNo){
      validations = 0;
    }

    if (validations) {
      setError(true)
      return
    }

    dispathInputsAdd(inputs)
    changeStep(4)
  }

  useEffect(() => {
    getStates()
  }, [])

  const getStates = async () => {
    await getinApi('get', 'states')
      .then((res) => {
        let states = [];
        res.states.forEach(state => {
          let stat = {
            value: state.state_code,
            label: state.state
          }
          states.push(stat);
        });
        setStates(states)
      })
      .catch((err) => console.log(err))
  }

  return (
    <div className="step-content step-3-add">
      <div className="backimage img20 img-3add">
        <div className='img'>
          <img className='center' src={"images/img-step20.svg"} alt="img-step20" />
        </div> 
      </div>
      <div className='contenido-licencia'>
        <h2 className='center h2'>{t('poseelicen.label')}</h2>
        <div className='flx form-3-add'>
          <div className='flx s-n'>
            <label className='checkbox label' htmlFor="si-licencia">
              <input id='si-licencia' type="radio" name='lactive' value='1' onClick={setInput}></input>
              <span className="checkmark"></span>
              {t('si.label')}
            </label>
            <label className='checkbox label' htmlFor="no-licencia">
              <input id='no-licencia' type="radio" name='lactive' value='0' onClick={setInput}></input>
              <span className="checkmark"></span>
              {t('no.label')}
            </label>
          </div>
          <div className='licencia'> 
            <div className='num-licencia'>
              <label className='label' htmlFor="mi-licencia" >
                {t('cuallicen.label')}
              </label>
              <div className='flx mi-lic'>
                <input id='mi-licencia' className='input' type='text' name='nro_license' placeholder={t('ejemlicen.label')} onChange={setInput} ></input>
              </div>
            </div>
            <div className='num-licencia'>
              <label className='label' htmlFor="mi-licencia" >
                {t('estadolicen.label')}
              </label>
              <div className='flx mi-lic'>
                <Select className='inp-ciudad inp-satatus slect-tag' id="inp-state" classNamePrefix="react-select" options={states} value={selectstate} onChange={setSelectState} placeholder={t('estado.label')} />
              </div>
            </div>
            <div className='label'>
              {t('activelicen.label')}
            </div>
            <div className='flx s-n'>
              <label className='checkbox label' htmlFor="si-activa">
                <input id='si-activa' type="radio" name='lic_active' value='1' onClick={setInput} ></input>
                <span className="checkmark inp-active"></span>
                {t('si.label')}
              </label>

              <label className='checkbox label' htmlFor="no-activa">
                <input id='no-activa' type="radio" name='lic_active' value='0' onClick={setInput} ></input>
                <span className="checkmark inp-active"></span>
                {t('no.label')}
              </label>
            </div>
            <div className="btns">
              <button className='center boton btn-2' type='submit' value='continuar' onClick={submitInputs}>{t('continuar.label')}</button> 
            </div>
          </div>
          <div className='motivos'>
            <h2 className='h2'>{t('cualmotivo.label')}</h2>
            <label className='checkbox label' htmlFor="motivo1">
              <input id='motivo1' type="radio" name='motivo' value='motivo1' onClick={submitInputs} ></input>
              <span className="checkmark"></span>
              {t('nuncaheten.label')}
            </label>

            <label className='checkbox label' htmlFor="motivo2">
              <input id='motivo2' type="radio" name='motivo' value='motivo2' onClick={submitInputs} ></input>
              <span className="checkmark"></span>
              {t('noposeolicen.label')}
            </label>

            <label className='checkbox label' htmlFor="motivo3">
              <input id='motivo3' type="radio" name='motivo' value='motivo3' onClick={submitInputs} ></input>
              <span className="checkmark"></span>
              {t('otro.label')}
            </label>
          </div>    
        </div>
      </div>
    </div>
  )
}