import React, { useState, useEffect } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

// File Conditions
import Conditions_es from "../assets/files/conditions_es.json";
import Conditions_en from "../assets/files/conditions_en.json";

export default function Step9({ dispathInputs, changeStep, lang, setError}) {

  // eslint-disable-next-line
  const { t } = useTranslation()

  const [cual, setCual] = useState(false)
  const [generalIds, setIds] = useState(1)
  const [nopadesco, setPadeco] = useState(false)
  // eslint-disable-next-line
  const [conditions, setConditions] = useState([])
  const [conditions_actual, setContionsP] = useState([])

  useEffect(() => {
    if (lang) {
      if (localStorage.getItem('lng') === 'es') {
        setConditions(Conditions_es)
      } else {
        setConditions(Conditions_en)
      }
    }
  }, [lang])

  useEffect(() => {
    $('#sp-slider').height($('#sp-slider li:nth-child(10) .step-content').outerHeight());
  }, [conditions_actual])

  const clearInvalid = () => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
  } 

  const setCheckCondition = e => {
    clearInvalid()
    setPadeco(false)
    $('#step10-no_pedesco_condicion').prop('checked', false);
    $(e.target).prop('checked', false);
    if (conditions_actual.length < 4) {
      $(e.target).prop('checked', true);
      let condition = {
        'id': e.target.name,
        'name': e.target.value,
        'label': e.target.id,
        'date_moth': null,
        'date_year': 0,
        'medicated': false,
        'medicines': []
      }
      let existe = conditions_actual.filter(function (data, i) {
        return data.name === condition.name
      });
      if (existe.length > 0) {
        setContionsP(
          conditions_actual.filter(function (data, i) {
            if (data.name === condition.name) {
              $('#' + data.label).prop('checked', false);
            }
            return data.name !== condition.name
          })
        )
      } else {
        setContionsP([...conditions_actual, condition])
      }
    }
  }

  const setNewCondition = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      let newCondition = {
        'id': 21,
        'name': e.target.value,
        'date_moth': null,
        'date_year': 0,
        'medicated': false,
        'medicines': []
      }
      setContionsP([...conditions_actual, newCondition])
    }
  }

  const createMedicine = condition => {
    clearInvalid()
    let new_medicine = {
      'id': generalIds,
      'name': '',
      'dose': '',
      'frequency': '',
      'date': null,
      'id_condition': condition,
    }
    let local_condition = [...conditions_actual]
    local_condition.filter(function (data, i) {
      if (data.id === condition) {
        data.medicated = true;
        if (data.medicines.length < 4) {
          data.medicines.push(new_medicine)
        }
      }
      return false
    });
    setContionsP(local_condition)
    setIds(generalIds + 1)
  }

  const deleteMedicines = condition => {
    clearInvalid()
    let local_condition = [...conditions_actual]
    local_condition.filter(function (data, i) {
      if (data.id === condition) {
        data.medicated = false;
        data.medicines = []
      }
      return false
    });
    setContionsP(local_condition)
  }

  const changecual = () => {
    setCual(!cual)
  }

  const setInputDiag = (e, condition) => {
    clearInvalid()
    let local_condition = [...conditions_actual]
    local_condition.filter(function (data, i) {
      if (data.id === condition) {
        data[e.target.name] = e.target.value
      }
      return false
    });
    setContionsP(local_condition)
  }

  const setInputMed = (e, condition, medicine) => {
    clearInvalid()
    let local_condition = [...conditions_actual]
    local_condition.filter(function (data, i) {
      if (data.id === condition) {
        data.medicines.filter(function (med, i) {
          if (med.id === medicine) {
            med[e.target.name] = e.target.value
          }
          return false
        });
      }
      return false
    });
    setContionsP(local_condition)
  }

  const submitInputs = () => {
    var validations = 0;

    if (!nopadesco && conditions_actual.length < 1) {
      $('.step-10-inp-no-padesco').addClass('invalide')
      validations = 1;
    }

    if (cual && conditions_actual.length < 1) {
      $('#step9-cual').addClass('invalide')
      validations = 1;
    }

    conditions_actual.forEach(diagnostic => {
      if (diagnostic.date_moth === null || diagnostic.date_moth === '' ) {
        $('.st10-date_moth_' + diagnostic.id).addClass('invalide')
        validations = 1;
      }

      if (diagnostic.date_year === 0 || (diagnostic.date_year).length > 4 || (diagnostic.date_year).length < 4 || isNaN(diagnostic.date_year) || (diagnostic.date_year).length === 0) {
        $('.st10-date_year_' + diagnostic.id).addClass('invalide')
        validations = 1;
      }


      if (diagnostic.medicated) {
        diagnostic.medicines.forEach(medicate => {
          if ((medicate.name).trim() === '') {
            $('.st10-name-medicine-' + medicate.id).addClass('invalide')
            validations = 1;
          }
          if ((medicate.dose).trim() === '') {
            $('.st10-dose-medicine-' + medicate.id).addClass('invalide')
            validations = 1;
          }
          if ((medicate.frequency).trim() === '') {
            $('.st10-frequency-medicine-' + medicate.id).addClass('invalide')
            validations = 1;
          }
        });
      }
    });

    if (validations) {
      setError(true)
      return
    }
    dispathInputs({'diagnostics_a': conditions_actual })
    changeStep(11)
  }

  const nopadescoF = () => {
    clearInvalid()   
    if (nopadesco === false) {
      setCual(false)
      setIds(1)
      setContionsP([])
    }
    setPadeco(!nopadesco)
  }

  return (
    <div className="step-content step-10">
      <h2 className='center h2'>{t('padecesalguna.label')}</h2>
      <div className=' flx condiciones_3'>
        <div className="backimage img10">
          <div className='img'>
            {/* <img className='center' src={"images/img-step10.svg"} alt="img-step10" /> */}
          </div>
        </div>

        <div className='form-st10 form_'>
          {(conditions.length > 0) && conditions.map((condition) =>
            <label className='checkbox label' htmlFor={'step10'+condition.name} key={condition.alias + '_' + condition.alias}>
              <input id={'step10'+condition.name} type='checkbox' defaultValue={condition.value} name={''+condition.id} onClick={setCheckCondition} />
              <span className="checkmark"></span>
              {condition.value}
            </label>
          )}

          <label className='checkbox label' htmlFor="actual-OTRO">
            <input id='actual-OTRO' type="checkbox" defaultValue='OTRO' onChange={changecual} />
            <span className="checkmark"></span>
            {t('OTRO.label')}
          </label>

          <div className='form-st10_2'>
            {cual &&
              <div className='cual animate__animated animate__bounceIn'>
                <label className='label'>
                  {t('cuál.label')}
                </label>
                <input className='input ' id='step10-cual' type="text" name="otro" onKeyDown={setNewCondition}  />
              </div>
            }
            <div className='nopadesco'>
              <label className='checkbox label' htmlFor="step10-no_pedesco_condicion">
                <input id='step10-no_pedesco_condicion' type="checkbox" onClick={() => nopadescoF()} defaultValue={nopadesco} />
                <span className="checkmark step-10-inp-no-padesco"></span>
                {t('nopadezco.label')}
              </label>
            </div>
          </div>
        </div>
      </div>
      { (conditions_actual.length > 0) && conditions_actual.map((condition, index) =>
        <div className={index === 0 ? 'condiciones_4 prime' : 'condiciones_4'} key={index + '_' + condition.id}>
          <div className='flx diagnostico-st10'>
            <div className='nombre-condicion '>
              <label className='label'>
                {t('nombrecondicion.label')}
              </label>
              <div className='flx'>
                <input className='input' id='step10-name-condicion' type="text" disabled name='name-condicion' defaultValue={condition.name} placeholder='' />
              </div>
            </div>

            <div className='fecha-diagnostico'>
              <label className='label'>
                {t('fechadiagnostico.label')}
              </label>
              <div className='flx'>
                <select className={'input st10-date_moth_' + condition.id} defaultValue={'DEFAULT'} name="date_moth" onChange={(e) => setInputDiag(e, condition.id)}>
                  <option value="DEFAULT" className='opt' disabled>{t('mes.label')}</option>
                  <option value="01"> {t('enero.label')}</option>
                  <option value="02"> {t('febrero.label')}</option>
                  <option value="03"> {t('marzo.label')}</option>
                  <option value="04"> {t('abril.label')}</option>
                  <option value="05"> {t('mayo.label')}</option>
                  <option value="06"> {t('junio.label')}</option>
                  <option value="07"> {t('julio.label')}</option>
                  <option value="08"> {t('agosto.label')}</option>
                  <option value="09"> {t('septiembre.label')}</option>
                  <option value="10"> {t('octubre.label')}</option>
                  <option value="11"> {t('noviembre.label')}</option>
                  <option value="12"> {t('diciembre.label')}</option>
                </select>
                <input className={'input st10-date_year_' + condition.id}  type="text" name='date_year' placeholder='Year' onChange={(e) => setInputDiag(e, condition.id)} />
              </div>
            </div>

            <div className='medicinas-pasadas'>
              <label className='label'>
                {t('tomamedicina.label')}
              </label>
              <div className='flx sino'>
                <label className='checkbox label' htmlFor={condition.id + 'si-st10'}>
                  <input id={condition.id + 'si-st10'} type='radio' defaultValue='si' name={'whith_medicines' + condition.id} onClick={() => createMedicine(condition.id)} />
                  <span className="checkmark"></span>
                  {t('si.label')}
                </label>

                <label className='checkbox label ' htmlFor={condition.id + 'no-st10'}>
                  <input id={condition.id + 'no-st10'} type="radio" defaultValue='no' name={'whith_medicines' + condition.id} onClick={() => deleteMedicines(condition.id)} />
                  <span className="checkmark"></span>
                  {t('no.label')}
                </label>
              </div>

            </div>
          </div>
          {(condition.medicines.length > 0) && condition.medicines.map((medicine, index) =>
            <div className={index === 0 ? 'flx medicamento-st10 fisrt animate__animated animate__bounceIn' : 'flx medicamento-st10 animate__animated animate__bounceIn'} key={index + '_' + condition.alias} >
              <div>
                <p id="cuales">{t('cuales.label')}</p>
              </div>
              <div className='flx celdas-st10'>
                <div className='celda-st10 a'>
                  <label className='label'>
                    {t('nombremedicina.label')}
                  </label>
                  <div className='flx'>
                    <input className={'input st10-name-medicine-' + medicine.id} id='step10-name-medicine' type="text" name='name' onChange={(e) => setInputMed(e, condition.id, medicine.id)} />
                  </div>
                </div>
                <div className='celda-st10 b'>
                  <label className='label'>
                    {t('dosis.label')}
                  </label>
                  <div className='flx'>
                    <input className={'input st10-dose-medicine-' + medicine.id} id='step10-dosis-medicamento' type="text" name='dose' onChange={(e) => setInputMed(e, condition.id, medicine.id)} />
                  </div>
                </div>
                <div className='celda-st10 c'>
                  <label className='label'>
                    {t('frecuencia.label')}
                  </label>
                  <div className='flx'>
                    <input className={'input st10-frequency-medicine-' + medicine.id} id='step10-frecuencia-medicina' type="text" name='frequency' onChange={(e) => setInputMed(e, condition.id, medicine.id)} />
                  </div>
                </div>
              </div>
            </div>)}
          {(condition.medicines.length > 0 && condition.medicines.length < 4) && <div className='add-medicine'>
            <button className='boton' type='submit' defaultValue='Añadir' onClick={() => createMedicine(condition.id)} >
              <div className='plus'>+</div><div>{t('añadirmedicina.label')}</div>
            </button>
          </div>}
        </div>)}
      <button className='final boton' type='submit' onClick={submitInputs} >{t('continuar.label')}</button>
    </div>
  )
}