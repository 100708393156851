import React, { useState } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Step7({ dispathInputs, changeStep, setError }) {

    // eslint-disable-next-line
  const { t } = useTranslation()

  const [medidle, setMedidle] = useState('Fett')
  const [inputs, setInputs] = useState({
    heght: '',
    weight: ''
  })

  const setInput = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    if (e.target.name === 'medidle' ){
      setMedidle(e.target.value )
    }else{
      setInputs({ ...inputs, [e.target.name]: e.target.value })
    }
  }
  const submitInputs = () => {
    let validations = 0;
    if ((inputs.heght).trim() === '') {
      $('.inp-height').addClass('invalide')
      validations = 1;
    }
    if ((inputs.weight).trim() === '') {
      $('.inp-weight').addClass('invalide')
      validations = 1;
    }

    if (validations) {
      setError(true)
      return
    }
    
    let inputs_copy =  { ...inputs } 
    inputs_copy.heght = inputs.heght + ' ' + medidle
    dispathInputs(inputs_copy)
    changeStep(8)
  } 

  return (
    <div className="step step-content step-7">
      <div className="backimage img7">
        <div className='img'>
          <img className='center' src={"images/img-step7.svg"} alt="img-step7" />
        </div>
      </div>
      <h2 className='center h2'>{t('cuentanosacerca.label')}</h2>

      <div className='flx form-st7 form_'>
        <div className='celda-st7'>
          <label className='label medida'>
            {t('talla.label')}
            <div className="help-tip" role="tooltip" id="name-tooltip">
              <div className="help-tip__content">
                <p>{t('sitalla.label')}</p>
              </div>
            </div>
          </label>
          <div className='flx'>
            <input className='input inp-height' type='text' name="heght" placeholder='num' onChange={setInput} />
            <select className='input' name="medidle" defaultValue={'Fett'} onChange={setInput}>
              <option value="Fett">Feet</option>
              <option value="Cms">Cm</option>
              <option value="Inchs">Inch</option>
            </select>
          </div>
        </div>
        <div className='celda-st7'>
          <label className='label peso'>
            {t('peso.label')} 
            <div className="help-tip" role="tooltip" id="name-tooltip">
              <div className="help-tip__content">
                <p>{t('sipeso.label')}</p>
              </div>
            </div>
          </label>
          <div className='flx'>
            <input className='input inp-weight' id='peso' type="text" name='weight' onChange={setInput} placeholder='Weight lbs' />
          </div>
        </div>
      </div>
      <button className='boton' onClick={submitInputs} type='submit' value='continuar'>{t('continuar.label')}</button>
    </div>
  )
}