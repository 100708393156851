import React from 'react'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Step8({ dispathInputs, changeStep, setError }) {

    // eslint-disable-next-line
  const { t } = useTranslation()

  const setInput = e => {
    dispathInputs({ [e.target.name]: e.target.value })
    nextStep()
  }
  const nextStep = () => {
    changeStep(9)
  }  

  return (
    <div className="step step-content step-8">
      <div className="backimage img8">
        <div className='img'>
          <img className='center' src={"images/img-step8.svg"} alt="img-step8" />
        </div>
      </div>
      <h2 className='center h2'>{t('consumesciga.label')}</h2>

      <div className='flx form form_'>

        <label className='checkbox label si' htmlFor="si">
          <input id='si' type='radio' name='smoker' value='1' onChange={setInput} />
          <span className="checkmark"></span>
          {t('si.label')}
        </label>

        <label className='checkbox label no' htmlFor="no">
          <input id='no' type="radio" name='smoker' value='0' onChange={setInput} />
          <span className="checkmark"></span>
          {t('no.label')}
        </label>

        <label className='checkbox label ya-no' htmlFor="ya-no">
          <input id='ya-no' type="radio" name='smoker' value='null' onChange={setInput} />
          <span className="checkmark"></span>
          {t('lodeje.label')}
        </label>

      </div>
    </div>
  )
}