import { useEffect } from 'react';
import Routes from './router';
import Header from './components/header'
import $ from 'jquery'
import { v1 as uuidv1 } from 'uuid';

// Trasnlation 
import './i18n'
import { useTranslation } from 'react-i18next'

// Store
import Store from './services/store' 

function App() {

  useEffect(() => {
    // notify segment of logged in user
    window.analytics.identify( uuidv1() ,{
      name: 'Emilan Request',
      attempt_id: Date.now()
    });

    // Track segment of logged in user
    window.analytics.page( "inside_page_home", {
      Step: 'Onbording',
      attempt_id: Date.now()
    });
    
    $('body').on('mouseover', '.help-tip', function () {
      if(!$(".help-tip__content.Clone").length){
        let off = $(this).offset()
        let clone = $(this).find('.help-tip__content').clone()
        clone.addClass('Clone animate__animated animate__bounceIn')
        $('body').append(clone)
        let h = ($('body .help-tip__content.Clone').outerHeight() / 2) - 9
        $('body .help-tip__content.Clone').attr('style', 'top:' + (off.top - h) + 'px; left:' + (off.left + 29) + 'px');
      }
      window.analytics.page("search_help", {
        value: 'true',
        attempt_id: Date.now()
      });
    })
    
    $(document).on('click', function () {
      $('body').find('.help-tip__content.Clone').addClass('animate__bounceIn')
      $('body').find('.help-tip__content.Clone').addClass('animate__bounceOut')
      setTimeout(() => {
        $('body').find('.help-tip__content.Clone').remove();
      }, 450);
    })

  }, [])

  const { i18n } = useTranslation()

  const initLang = () => {
    i18n.changeLanguage(localStorage.getItem('lng'))
  }

  return (
    <Store>
      <div className="App" onLoad={initLang}>
        <Header />
        <Routes />
      </div>
    </Store>
  );
}

export default App;
