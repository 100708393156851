import React from 'react'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Step13({ dispathInputs, changeStep, setError}) {

    // eslint-disable-next-line
  const { t } = useTranslation()

  const setInput = e => {
    e.preventDefault();
    if (e.target.name === 'provision_exam'){
      dispathInputs({ [e.target.name]: e.target.value })
      nextStep()
    }else{
      dispathInputs({ [e.target.name]: e.target.value })
    }
  }
  const nextStep = () => {
    changeStep(14)
  }  

  return (
    <div className="step-content step-13">
      <div className="backimage img13">
        <div className='img'>
          <img className='center' src={"images/img-step13.svg"} alt="img-step13" />
        </div>
      </div>
      <h2 className='center h2'>{t('dispuestoexamen.label')}</h2>
      <div className='form-st13 form_'>
        <div className='flx btns_st13-1'>
          <button className='boton' type='submit' onClick={setInput} name="provision_med" value='1'>{t('si.label')}</button>
          <button className='boton' type='submit' onClick={setInput} name="provision_med" value='0'>{t('no.label')}</button>
        </div>
        <h3 className='center h3'>{t('mejorestarifas.label')}</h3>
        <div className='flx btns_st13-2'>
          <button className='boton' type='submit' onClick={setInput} name="provision_exam" value='1'>{t('si.label')}</button>
          <button className='boton' type='submit' onClick={setInput} name="provision_exam" value='0'>{t('no.label')}</button>
        </div>
      </div>
      <footer>
        <p>{t('porfavorten.label')}</p>
      </footer>
    </div>
  )
}