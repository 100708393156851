import React from 'react'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Steper({step}) {

  const { t } = useTranslation()

  return (
    <div className="content_steaper"> 
      <div>
        <div>
          <div className={`${(step > 1) ? 'step_active' : ''} ${(step === 1) ? 'step_active' : ''}`}>
            <div className="circle">
              <i className="fa fa-check"></i>
              <span>1</span>
            </div>
            { t('inf.label') === 'es' ? 
              <span>{t('inf.label')}<br />{t('basica.label')}</span>
              : 
              <span>{t('basica.label')}<br />{t('inf.label')}</span>
            } 
            <div className="left_line"></div>
          </div>
          <div className={`${(step > 2) ? 'step_active' : ''} ${(step === 2) ? 'step_semiactive' : ''}`}>
            <div className="circle">
              <span>2</span>
            </div>
            <span>{t('selec.label')}<br />{t('cuota.label')}</span>
            <div className="left_line"></div>
            <div className="right_line"></div>
          </div>
          <div className={`${(step > 3) ? 'step_active' : ''} ${(step === 3) ? 'step_semiactive' : ''}`} >
            <div className="circle">
              <span>3</span>
            </div>
            {t('inf.label') === 'es' ?
              <span>{t('dato.label')}<br />{t('adicional.label')}</span>
              :
              <span>{t('adicional.label')}<br />{t('dato.label')}<br /></span>
            }
            <div className="left_line"></div>
            <div className="right_line"></div>
          </div>
          <div className={`${(step > 4) ? 'step_active' : ''} ${(step === 4) ? 'step_semiactive' : ''}`}>
            <div className="circle">
              <span>4</span>
            </div>
            <span>{t('beneficiarios.label')}</span>
            <div className="left_line"></div>
            <div className="right_line"></div>
          </div>
          <div className={`${(step > 5) ? 'step_active' : ''} ${(step === 5) ? 'step_semiactive' : ''}`}>
            <div className="circle">
              <span>5</span>
            </div>
            {t('inf.label') === 'es' ?
              <span>{t('inf.label')}<br />{t('pay.label')}</span>
              :
              <span>{t('final.label')}<br />{t('pay.label')}</span>
            }
            <div className="right_line"></div>
          </div>
        </div>
      </div>
    </div>
  )
}



