import React from 'react'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Step3({ dispathInputs, changeStep, setError}) {

    // eslint-disable-next-line
  const { t } = useTranslation()

  const setInput = e => {
    dispathInputs({ [e.target.name] : e.target.value})
    nextStep()
  } 
  const nextStep = () => {
    changeStep(4)
  }  

  return (
    <div className="step step-content step-3">
      <div className ="backimage img3">
        <div className='img'>
          <img className='center'  src={"images/img-step3.svg"} alt="img-step3"/>
        </div>
      </div>
      <h2 className='center h2'>{t('cualgenero.label')}</h2>
      <div className='flx form-st3 form_'>
        <label className='checkbox genero' htmlFor="hombre">
          <input id='hombre' type='radio' name='genre' defaultValue='Male' onChange={setInput} />
          <span className="checkmark"></span>
          {t('hombre.label')}
        </label>
        <label className='checkbox genero' htmlFor="mujer">
          <input id='mujer' type="radio" name='genre' defaultValue='Female' onChange={setInput} />
          <span className="checkmark"></span>
          {t('mujer.label')}
        </label>
        <label className='checkbox genero' htmlFor="otro">
          <input id='otro' type="radio" name='genre' defaultValue='Otro' onChange={setInput} />
          <span className="checkmark"></span>
          {t('otro.label')}
        </label>
      </div>
    </div>
  )
}