import React, { useState, useEffect } from 'react'
import $ from 'jquery'

// Services
import { getinApi } from '../services/api'
import Select from 'react-select'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Step12({ dispathInputs, changeStep, setError }) {

  // eslint-disable-next-line
  const { t } = useTranslation()
  let [states, setStates] = useState([])
  let [cities, setCities] = useState([])
  let [selectcity, setSelCity] = useState()
  let [selectstate, setSelState] = useState()
  const [infodc, setInfoc] = useState(false)
  const [info_dr, setInput_Dr] = useState({
    name_doc:'',
    city_doc:'',
    state_doc:'',
    month_doc:'',
    year_doc: ''
  })

  const setSelectState = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    setSelState(e)
    setSelCity(null)
    getCities(e.value)
    setInput_Dr({ ...info_dr, state_doc: e.value, city_doc: '' })
  }

  const setSelectCity = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    setSelCity(e)
    setInput_Dr({ ...info_dr, city_doc: e.value })
  }

  useEffect(() => {
    $('#sp-slider').height($('#sp-slider li:nth-child(12) .step-content').outerHeight());
    getStates()
  }, [infodc])

  const setInput = e => {
    e.preventDefault();
    if (e.target.value === '1'){
      setInfoc(true)
    } else {
      dispathInputs({ [e.target.name]: e.target.value })
      changeStep(13)
    }
  }

  const setInputDr = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    if (e.target.name === 'state_doc') {
      getCities(e.target.value)
    }
    setInput_Dr({ ...info_dr, [e.target.name]: e.target.value })
  }

  const submitInputs = () => {
    let validations = 0;
    if ((info_dr.name_doc).trim() === '') {
      $('.inp-name_doc').addClass('invalide')
      validations = 1;
    }
    if ((String(info_dr.city_doc)).trim() === '') {
      $('.inp-city_doc').addClass('invalide')
      validations = 1;
    }
    if ((info_dr.state_doc).trim() === '') {
      $('.inp-state_doc').addClass('invalide')
      validations = 1;
    }
    if ((info_dr.month_doc).trim() === '') {
      $('.inp-month_doc').addClass('invalide')
      validations = 1;
    }
    console.log(info_dr.year_doc);
    if ((info_dr.year_doc).trim() === '') {
      $('.inp-year_doc').addClass('invalide')
      validations = 1;
    }

    if (validations) {
      setError(true)
      return
    }

    dispathInputs({ info_dr: info_dr })
    changeStep(13)
  } 

  const getStates = async () => {
    await getinApi('get', 'states')
      .then((res) => {
        let states = [];
        res.states.forEach(state => {
          let stat = {
            value: state.state_code,
            label: state.state
          }
          states.push(stat);
        });
        setStates(states)
      })
      .catch((err) => console.log(err))
  }
  const getCities = async (id_state) => {
    await getinApi('get', `states/${id_state}/cities`)
      .then((res) => {
        let cities = [];
        res.cities.forEach(city => {
          let cit = {
            value: city.id,
            label: city.name
          }
          cities.push(cit);
        });
        setCities(cities)
      })
      .catch((err) => console.log(err))
  }
  return (
    <div className="step-content step step-12">
      <h2 className='center h2'>{t('ahoracuentanos.label')}</h2>
      <div className="backimage img12">
        <div className='img'>
          <img className='center' src={"images/img-step6.svg"} alt="img-step12" />
        </div>
      </div>
      <div className='form-st12 form_'>
        <div className='flx btns-st12'>
          <button className='boton' onClick={setInput} name="info_dr" value='1'>{t('si.label')}</button>
          <button className='boton' onClick={setInput} name="info_dr" value='0'>{t('no.label')}</button>
        </div>
        {infodc && 
          <div className='flx-st12'>
            <div className='nombre-doctor'>
              <label className='label'>
              {t('nombredoc.label')}
              </label>
              <div className='flx'>
              <input className='input inp-name_doc' id='name-doc' onChange={setInputDr} type="text" name='name_doc' />
              </div>
            </div>
            <div className='nombre-ciudad'>
              <label className='label' >
               {t('estado.label')}
              </label>
              <div className='flx'>
                <Select className='inp-ciudad inp-state_doc slect-tag' classNamePrefix="react-select" options={states} value={selectstate} onChange={setSelectState} placeholder={t('estado.label')} />
              </div>
            </div>
            <div className='nombre-ciudad'>
              <label className='label' >
               {t('ciudad.label')}
              </label>
              <div className='flx'>
                <Select className='inp-ciudad inp-city_doc slect-tag' classNamePrefix="react-select" options={cities} value={selectcity} onChange={setSelectCity} placeholder={t('ciudad.label')} />
              </div>
            </div>
            <div className='fecha-visita'>
              <label className='label'>
              {t('fechavisita.label')}
              </label>
              <div className='flx'>
              <select className='input inp-month_doc' defaultValue={'DEFAULT'} onChange={setInputDr} name="month_doc" >
                <option value="DEFAULT" className='opt' disabled>{t('mes.label')}</option>
                <option value="01"> {t('enero.label')}</option>
                <option value="02"> {t('febrero.label')}</option>
                <option value="03"> {t('marzo.label')}</option>
                <option value="04"> {t('abril.label')}</option>
                <option value="05"> {t('mayo.label')}</option>
                <option value="06"> {t('junio.label')}</option>
                <option value="07"> {t('julio.label')}</option>
                <option value="08"> {t('agosto.label')}</option>
                <option value="09"> {t('septiembre.label')}</option>
                <option value="10"> {t('octubre.label')}</option>
                <option value="11"> {t('noviembre.label')}</option>
                <option value="12"> {t('diciembre.label')}</option>
              </select>
              <select className='input select inp-year_doc' defaultValue={'DEFAULT'} onChange={setInputDr} id='year-doc' name='year_doc' >
                <option value="DEFAULT" className='opt' disabled>{t('año.label')}</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
              </select>
              </div>
            </div>
          </div> }
        {infodc && 
          <button className='boton' onClick={submitInputs} type='submit' value='continuar'>{t('continuar.label')}</button>
        }
      </div>
      {infodc && 
        <footer>
        <p>{t('enesteespacio.label')}</p>
       </footer> 
      }
    </div>
  )
}