import React, { useState } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../../i18n'
import { useTranslation } from 'react-i18next'

export default function Step1({ dispathInputsAdd, changeStep, setError }) {

  // eslint-disable-next-line
  const { t } = useTranslation()

  const [resident, setResi] = useState(false)
  const [itin, setItin] = useState(false)
  const [inputs, setInputs] = useState({
    country_birth: '',
    status_migrate: '',
    uscis: '',
    ITIN: ''
  })

  const clearInvalid = () => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
  }

  const setInput = e => {
    clearInvalid()
    setInputs({ ...inputs, [e.target.name]: e.target.value }); 
  }

  const setInputRdio = e => {
    clearInvalid()
    unSet()
    setInputs({ ...inputs, 'status_migrate': '1' })
  }
  
  const unSet = e => {
    setItin(false)
    setResi(false)
    $('.num-alien').attr('style', 'display:none;'); 
    $('.num-itin').attr('style', 'display:none;'); 
    $('.itin-si').attr('style', 'display:none;'); 
  }

  const setAlen = e => {
    clearInvalid()
    setResi(true)
    setItin(false)
    setInputs({ ...inputs, 'status_migrate': '0' , ITIN: '' })
    $('#num-ITIN').val('')
    $('.num-itin').attr('style', 'display:none;'); 
    $('.num-alien').attr('style', 'display:flex;'); 
  }
  const seITIN = e => {
    clearInvalid()
    setResi(false)
    setItin(true)
    setInputs({ ...inputs, 'status_migrate': '0', uscis: '' })
    $('#num-USCIS').val('')
    $('.num-alien').attr('style', 'display:none;'); 
    $('.num-itin').attr('style', 'display:flex;'); 
  }
  const seITINNum = e => {
    clearInvalid()
    if (e.target.value === 'no-itin'){
      $('.itin-si').attr('style', 'display:none;'); 
      setItin(false)
    }else{
      $('.itin-si').attr('style', 'display:flex;'); 
      setItin(true)
    }
  }

  const submitInputs = () => {
    
    let validations = 0;
    if ((inputs.country_birth).trim() === '') {
      $('.inp-country_birth').addClass('invalide')
      validations = 1;
    }

    // console.log(inputs.status_migrate)

    if ((inputs.status_migrate).trim() === '') {
      $('.inp-status_migrate').addClass('invalide')
      validations = 1;
    }
    if ((inputs.uscis).trim() === '' && resident) {
      $('#num-USCIS').addClass('invalide')
      validations = 1;
    }
    if ((inputs.ITIN).trim() === '' && itin ) {
      $('#num-ITIN').addClass('invalide')
      validations = 1;
    }

    if (validations) {
      setError(true)
      return
    }

    dispathInputsAdd(inputs)
    changeStep(2)
  }

  return (
    <div className="step-1-add step-content">
      <div className='flx pais'>
        <div className='titulo-pais'>
          <h2 className='center h2'>{t('paisnacim.label')}</h2>
        </div>
        <div className='flx wd-sel'>
          <input className='input inp-country_birth' name='country_birth' onChange={setInput} placeholder={t('selecpais.label')} />
        </div>
      </div>
      <div className='flx contenido-estado'>
        <div className ="backimage img18 img-1add">
          <div className='img'>
            <img className='center' src={"images/img-step18.svg"} alt="img-step18" />
          </div>
        </div>
        <div className='flx form-1-add'>
          <h2 className='center h2'>{t('estadomig.label')}</h2>
          <div className='options-1add'>
            <label className='checkbox label' htmlFor="ciudadano">
              <input id='ciudadano' type='radio' name='status_migrate' value='1' onClick={setInputRdio}></input>
              <span className="checkmark inp-status_migrate"></span>{t('ciudadano.label')}
            </label>
            <label className='checkbox label' htmlFor="residente">
              <input id='residente' type="radio" name='status_migrate' value='0' onClick={setAlen}></input>
              <span className="checkmark inp-status_migrate"></span>{t('residente.label')}
            </label>
            <label className='checkbox label' htmlFor="permiso" >
              <input id='permiso' type="radio" name='status_migrate' value='0' onClick={setAlen}></input>
              <span className="checkmark inp-status_migrate"></span>{t('permisotrab.label')}
            </label>
            <label className='checkbox label' htmlFor="otro-emigrate">
              <input id='otro-emigrate' type="radio" name='status_migrate' value='0' onClick={seITIN}></input>
              <span className="checkmark inp-status_migrate"></span>{t('otro.label')}
            </label>
          </div>
          <div className='flx num-alien'>
            <div className='titulo-alien'>
              <h2 className='h2'>{t('cualnumalien.label')}
                <div className="help-tip" role="tooltip" id="name-tooltip">
                  <div className="help-tip__content st1-4">
                    <h3 className="help-tip__title">{t('numalien.label')}</h3>
                    <p>
                      {t('explnumalien.label')}
                    </p>
                    <button className='boton' type='submit' id="close-help-tip" value='continuar'>{t('entendido.label')}</button>
                  </div>
                </div>
              </h2>
            </div>
            <div className='flx wd-sel'>
              <input id='num-USCIS' className='input' type='number' name='uscis' placeholder={t('numalien.label')} onChange={setInput} />
            </div>
          </div> 
          <div className='flx num-itin'>
            <div className='titulo-itin'>
              <h2 className='h2'>{t('poseenumitin.label')}
              </h2>
              <div className="help-tip" role="tooltip" id="name-tooltip">
                <div className="help-tip__content st1-4">
                  <h3 className="help-tip__title">{t('numitin.label')}</h3>
                  <p>
                    {t('explnumitin.label')}
                  </p>
                  <button className='boton' type='submit' id="close-help-tip" value='continuar'>{t('entendido.label')}</button>
                </div>
              </div>
            </div>
            <div className='flx s-n'>
              <label className='checkbox label' htmlFor="si-itin">
                <input id='si-itin' type="radio" name='num-itin' value='si-itin' onClick={seITINNum}></input>
                <span className="checkmark"></span>
                {t('si.label')}
              </label>
              <label className='checkbox label' htmlFor="no-itin">
                <input id='no-itin' type="radio" name='num-itin' value='no-itin' onClick={seITINNum}></input>
                <span className="checkmark"></span>
                {t('no.label')}
              </label>
            </div>
            <div className='flx wd-sel itin-si'>
              <input id='num-ITIN' className='input' type='number' name='ITIN' placeholder={t('numitin.label')} onChange={setInput} />
            </div>
          </div>
          <button className='center boton btn-2' value='continuar' onClick={submitInputs} >{t('continuar.label')}</button> 
        </div>
      </div>
    </div>
  )
}