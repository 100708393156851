import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../services/store'
import moment from 'moment';

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

// Services
import { getinIXN } from '../services/ixn'
import $ from 'jquery'

// Components 
import Steper from '../components/stepper'
import Slider from "rc-slider/lib/Slider";
import "rc-slider/assets/index.css";

export default function Calculator({ dispathInputs, changeStep, calcu }) {

  // eslint-disable-next-line
  const { t } = useTranslation()
  // @ts-ignore
  const [state] = useContext(Context);
  let [carrier, setCarrier] = useState([])
  let [termRecomeded, setTermRecomeded] = useState(0)
  let [coverRecomeded, setCoverRecomeded] = useState(0)
  let [quotes, setQuotes] = useState([])
  let [value_term, setValue] = useState(5)
  let [selected, setSelected] = useState(3)
  let [termopt, setTermopt] = useState({opt:null, term:'' , 'type':'', 'value':0})

  const [coverage, setCoverage] = useState({
    price: '',
    term: '',
    coverage: 'Standart',
    genre: 0,
    period: '',
    plan_id: 1,
    face_amt: 0
  })

  
  const [quote, setQuote] = useState({
    "state": "UT",
    "gender": "Male",
    "current_age": 30,
    "nearest_age": 31,
    "tobacco": false,
    "face_amount": value_term * 100000,
    "product_types": null,
    "health_categories": ["Standard"],
    "carrier_ids": [1594]
  })

  const setInputs = e => {
    if(value_term !== e){
      setValue(e)
      setQuote({ ...quote, face_amount: e * 100000 })
    }
  }

  const ranges = { 1: "100K", 2.5: "", 3: "", 5: "", 7.5: "", 10: "1M" }

  // eslint-disable-next-line
  useEffect(() => { 
    if(calcu){
      getCarrier() 
    }
  }, [calcu])
  // eslint-disable-next-line
  useEffect(() => { getQuote() }, [carrier]);
  // eslint-disable-next-line
  useEffect(() => { getQuote(); setTermopt({ opt: null, term: '', 'type': '', 'value': 0 }) }, [value_term]);

  const getCarrier = async () => {
    await getinIXN('get_carrier')
      .then((res) => {
        let terms = []
        res.forEach(term => {
          terms.push(term.product_type)
        });
        // var years = moment().diff(state.inputs.birthday, 'years', true)+'';
        var years = moment().diff('1989-04-15', 'years', true) + '';
        var splyear = years.split('.')
        var age = splyear[0] - 0;
        if (age > 50 ) {
          setTermRecomeded(20)
        }else{
          setTermRecomeded(30)
        }
        var neare = age - 0;
        if ((splyear[1].substring(0, 1)) - 0 > 6) {
          neare = age - 0 + 1
        }
        let recomended = ((state.inputs.income / 100000) * 15);
        // console.log(recomended);
        setValue(recomended);
        setCoverRecomeded(recomended);
        setQuote({ ...quote, state: state.inputs.state, product_types: terms, current_age: age - 0, nearest_age: neare - 0, gender: state.inputs.genre, tobacco: state.inputs.smoker, face_amount: recomended * 100000 })
        setCarrier(res)
      })
      .catch((err) => console.log(err))
  }

  const getQuote = async () => {
    if (quote.product_types){
      await getinIXN('get_quotes', quote)
        .then((res) => {
          setQuotes(res)
          $('.price_list .item').addClass('animate__animated animate__bounceIn')
          setTimeout(() => {
            $('.price_list .item').removeClass('animate__animated animate__bounceIn')
          }, 455)
        })
        .catch((err) => console.log(err))
    }
  }

  const setSel = (index, term) => {
    if ((index+1) === selected ){
      if (termopt.opt){
        window.analytics.track('Term Selected', {
          term: coverage.term,
          attempt_id: Date.now()
        });
        dispathInputs({ 'coverage': coverage })
        changeStep(18)
        setSelected(index + 1)
      }else{
        alert(`Error: Selecciona Uno`)
      }
    }else{
      setSelected(index + 1)
      $('.pricebox').removeClass('active')
      $('.pricebox:nth-child(' + (index + 1) +')').addClass('active')
      setTermopt({ opt: null, term: '', 'type': '', 'value': 0 })
    }
  }

  const setSelTerm = (opt , term , type, value, index ) => {
    if((index + 1) === selected) setTermopt({ opt: opt, term: term, 'type': type, 'value': value})
    var arrayDeCadenas = term.split(' ');
    if ((index + 1) === selected) setCoverage({ ...coverage, term: arrayDeCadenas[0], 'period': type, 'price': value, 'face_amt': (value_term * 100)})
  }

  const flexFont = () => {
    var divs = $(".flexFont");
    for (var i = 0; i < divs.length; i++) {
      var relFontsize = divs[i].offsetWidth * 0.27
      divs[i].style.fontSize = relFontsize + 'px'
    }
  }
  const flexFontM = () => {
    var divs = $(".flexFontM")
    for (var i = 0; i < divs.length; i++) {
      var relFontsize = divs[i].offsetWidth * 0.25
      divs[i].style.fontSize = relFontsize + 'px'
    }
  }

  window.onresize = function (event) {
    flexFont()
    flexFontM()
  }
  
  return (
    <div className="content_box calculator view">
      <Steper step={2} />
      <div className="form_content">
        <h3>{t('terecomendaunaa.label')} {termRecomeded} {t('terecomendaunab.label')}</h3>
        <p>{t('pofavselec.label')}</p>
        <form>
          <h2>{t('cobertura.label')}</h2>
          <div className="slider-wrapper">
            <label>${value_term * 100},000</label>
            <span>{t('recomen.label')}</span>
            <label className="recomended">${coverRecomeded * 100},000</label>
            <Slider min={1} max={10} defaultValue={value_term} value={value_term} marks={ranges} step={null} onChange={setInputs} />
          </div>
        </form>
        <h2 className="terms">{t('termaño.label')}</h2>
      </div>
      <div className="prices">
        {quotes.length > 0 && quotes.map((quote, index) => 
          (index < 4) ?
            <div key={index + 'quotes'} className={((index + 1) === selected) ? 'pricebox active animate__animated animate__bounceIn' : 'pricebox'} >
              <h2 className="header">{quote.product_type}</h2>
              <div className='price_list'>
                <div className={`item ${((index + 1) === selected) ? 'flexFont' : 'flexFontM'} ${(termopt.opt === 1 && termopt.term === quote.product_type) ? 'active' : ''}`} onClick={() => setSelTerm(1, quote.product_type, 'monthly', quote.premium_monthly, index)} >
                  <div className="circle"><i></i></div>
                  <span>$</span>
                  {quote.premium_monthly} 
                  <div className="type flexFontS" >{t('mes.label')}</div>
                </div>
                <div className={(termopt.opt === 2 && termopt.term === quote.product_type) ? 'item active' : 'item'} onClick={() => setSelTerm(2, quote.product_type, 'quarterly', quote.premium_quarterly, index)}>
                  <div className="circle"><i></i></div>
                  <span>$</span>
                  {quote.premium_quarterly}
                  <div className="type">{t('trim.label')}</div>
                </div>
                <div className={(termopt.opt === 3 && termopt.term === quote.product_type) ? 'item active' : 'item'} onClick={() => setSelTerm(3, quote.product_type, 'semi_annual', quote.premium_semi_annual, index)}>
                  <div className="circle"><i></i></div>
                  <span>$</span>
                  {quote.premium_semi_annual} 
                  <div className="type">{t('sem.label')}</div>
                </div>
                <div className={(termopt.opt === 4 && termopt.term === quote.product_type) ? 'item active' : 'item'} onClick={() => setSelTerm(4, quote.product_type, 'annual', quote.premium_annual, index)}>
                  <div className="circle"><i></i></div>
                  <span>$</span>
                  {quote.premium_annual} 
                  <div className="type">{t('año.label')}</div>
                </div>
              </div>
              <div className="btn_price" onClick={() => setSel(index)}>{((index + 1) === selected) ?  t('aplic.label')  :  t('verm.label')  }</div>
            </div> : ''
        )}
      </div>
    </div>
  )
}


