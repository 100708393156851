import React, { useState } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../../i18n'
import { useTranslation } from 'react-i18next'

export default function Step2({ dispathInputsAdd, changeStep, setError}) {

  // eslint-disable-next-line
  const { t } = useTranslation()

  const [inputs, setInputs] = useState({
    ocupation_work: '',
    name_work: ''
  })

  const setInput = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }
  const submitInputs = () => {
    let validations = 0;
    if ((inputs.ocupation_work).trim() === '') {
      $('.inp-ocupation').addClass('invalide')
      validations = 1;
    }
    if ((inputs.name_work).trim() === '') {
      $('.inp-company').addClass('invalide')
      validations = 1;
    }

    if (validations) {
      setError(true)
      return
    }

    dispathInputsAdd(inputs)
    changeStep(3)
  }

  return (
    <div className="step-content step-2-add">
      <div className="backimage img19 img-2add">
        <div className='img'>
          <img className='center' src={"images/img-step19.svg"} alt="img-step19" />
        </div>
      </div>
      <div className='contenido-ocupacion'>
        <h2 className='center h2'>{t('cuentaocupacion.label')}</h2>
        <div className='flx form-2-add'>
          <label className='label' htmlFor="empleo">
            {t('queempleo.label')}
          </label>
          <div className='flx'>
            <input id='empleo' className='input inp-ocupation' type='text' name='ocupation_work' placeholder={t('ejemempleo.label')} onChange={setInput}></input>
          </div>
          <label className='label' htmlFor="compania">
            {t('quecompan.label')}
          </label>
          <div className='flx'>
            <input id='compania' className='input inp-company' type='text' name='name_work' placeholder={t('ejemcompan.label')} onChange={setInput} ></input>
          </div>
        </div>
        <button className='center boton btn-2' value='continuar' onClick={submitInputs} >{t('continuar.label')}</button> 
      </div>  
    </div>
  )
}