import React from 'react'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Step11({ dispathInputs, changeStep, setError }) {

    // eslint-disable-next-line
  const { t } = useTranslation()

  const setInput = e => {
    e.preventDefault();
    dispathInputs({ [e.target.name]: e.target.value })
    nextStep()
  }

  const nextStep = () => {
    changeStep(12)
  }  

  return (
    <div className="step-content step-11">
      <h2 className='center h2'>{t('algunfamiliar.label')}</h2>
      <div className="backimage img11">
        <div className='img'>
          <img className='center' src={"images/img-step11.svg"} alt="img-step11" />
        </div>
      </div>
      <div className='flx form-st11 form_'>
        <button className='boton' onClick={setInput} name="health_family" value='1'>{t('si.label')}</button>
        <button className='boton' onClick={setInput} name="health_family" value='0'>{t('no.label')}</button>
      </div>
    </div>
  )
}