import React, { useState } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../../i18n'
import { useTranslation } from 'react-i18next'

export default function Step2({ dispathInputsBene, changeStep, setError }) {

  // eslint-disable-next-line
  const { t } = useTranslation()

  const [death_father, setDeathF] = useState(false)
  const [death_mother, setDeathM] = useState(false)
  const [parents, setInputParents] = useState([
      {
        "relationship": "Padre",
        "age": '',
        "death": 0,
        "death_cause": "no death"
      },
      {
        "relationship": "Madre",
        "age": '',
        "death": 0,
        "death_cause": "no death"
      }
    ])

  const setInput = (e, parent) => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    let local_parent = [...parents]
    local_parent.filter(function (data, i) {
      if (data.relationship === parent) {
        if (e.target.name === 'death_ma'){
          data['death'] = e.target.value
          setDeathM(true)
          if (e.target.value === '0' ){
            $('#edad-actual-Madre').show();
            $('#edad-muerte-Madre').hide();
            $('.causa-death-Madre').hide();
          }else{
            $('#edad-actual-Madre').hide();
            $('#edad-muerte-Madre').show();
            $('.causa-death-Madre').show();
          }
        }

        if (e.target.name === 'death_pa') {
          data['death'] = e.target.value
          setDeathF(true)
          if (e.target.value === '0') {
            $('#edad-actual-Padre').show();
            $('#edad-muerte-Padre').hide();
            $('.causa-death-Padre').hide();
          } else {
            $('#edad-actual-Padre').hide();
            $('#edad-muerte-Padre').show();
            $('.causa-death-Padre').show();
          }
        } 

        if (e.target.name !== 'death_pa' || e.target.name !== 'death_ma'){
          data[e.target.name] = e.target.value
        }

      }
      return false
    });
    setInputParents(local_parent)
  }

  const submitInputs = () => {

    let validations = 0;
    if(!death_father){
      $('.inp-death-Padre').addClass('invalide')
      validations = 1;
    }
    if(!death_mother){
      $('.inp-death-Madre').addClass('invalide')
      validations = 1;
    }

    parents.filter(function (data, i) {
      if (data.death === "0") {
        if ((data.age).trim() === '') {
          $('#edad-actual-' + data.relationship).addClass('invalide')
          validations = 1;
        }
      }else{
        if ((data.age).trim() === '') {
          $('#edad-muerte-'+ data.relationship).addClass('invalide')
          validations = 1;
        }
        if ((data.death_cause).trim() === '' || (data.death_cause) === 'no death' ) {
          $('.causa-death-' + data.relationship ).addClass('invalide')
          validations = 1;
        }
      }
    });

    if (validations) {
      setError(true)
      return
    }

    dispathInputsBene({ 'parents': parents })
    nextStep()
  } 

  const nextStep = () => {
    changeStep(3)
  }

  return (
    <div className="step-content step-2-beneficiaries">
      <div className="backimage img22 img-2bene">
        <div className='img'>
          <img className='center' src={"images/img-step22.svg"} alt="img-step22" />
        </div>
      </div>
      <div className='contenido-padres'>
        <h2 className='center h2'>{t('acercadepadres.label')}</h2>
        <div className='flx form-2-benef'>
          <div className='flx info-mom'>
            <div className='h3 padre'>{t('madre.label')}</div>
            <label className='checkbox label a' htmlFor="si-vive-mom" >
              <input id='si-vive-mom' type="radio" name='death_ma' value='0' onClick={(e) => setInput(e, 'Madre')}></input>
              <span className="checkmark inp-death-Madre"></span>
              {t('vive.label')}
            </label>
            <div className='flx b'>
              <input id='edad-actual-Madre' className='input' type='number' name='age' placeholder={t('edadact.label')}  onChange={(e) => setInput(e, 'Madre')} ></input>
            </div>
            <label className='checkbox label c' htmlFor="no-vive-mom" >
              <input id='no-vive-mom' type="radio" name='death_ma' value='1' onClick={(e) => setInput(e, 'Madre')}></input>
              <span className="checkmark inp-death-Madre"></span>
              {t('facelli.label')}
            </label>
            <div className='flx d'>
              <input id='edad-muerte-Madre' className='input' type='number' name='age' placeholder={t('aqueedad.label')}  onChange={(e) => setInput(e, 'Madre')}></input>
            </div>
            <div className='flx e'>
              <input className='input causa-death-Madre' type='text' name='death_cause' placeholder={t('causa.label')}  onChange={(e) => setInput(e, 'Madre')} ></input>
            </div>
          </div>
          <div className='flx info-dad'>
            <div className='h3 padre'>{t('padre.label')}</div>
            <label className='checkbox label a' htmlFor="si-vive-dad" >
              <input id='si-vive-dad' type="radio" name='death_pa' value='0' onClick={(e) => setInput(e, 'Padre')}></input>
              <span className="checkmark inp-death-Padre"></span>
              {t('vive.label')}
            </label>
            <div className='flx b'>
              <input id='edad-actual-Padre' className='input' type='number' name='age' placeholder={t('edadact.label')}  onChange={(e) => setInput(e, 'Padre')} ></input>
            </div>
            <label className='checkbox label c' htmlFor="no-vive-dad">
              <input id='no-vive-dad' type="radio" name='death_pa' value='1' onClick={(e) => setInput(e, 'Padre')}></input>
              <span className="checkmark inp-death-Padre"></span>
              {t('facelli.label')}
            </label>
            <div className='flx d'>
              <input id='edad-muerte-Padre' className='input' type='number' name='age' placeholder={t('aqueedad.label')}  onChange={(e) => setInput(e, 'Padre')} ></input>
            </div>
            <div className='flx e'>
              <input className='input causa-death-Padre' type='text' name='death_cause' placeholder={t('causa.label')} onChange={(e) => setInput(e, 'Padre')} ></input>
            </div>
          </div>
        </div>
        <button className=' center boton btn-2' type='submit' value='continuar' onClick={submitInputs}>{t('continuar.label')}</button>
      </div>
    </div>
  )
}