import React, { useEffect, useContext, useState } from 'react'
import { Context } from '../services/store'
import $ from 'jquery'

import Steper from '../components/stepper'
// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

// Services
import { getinApi } from '../services/api'

export default function Thanks({ changeStep , ready }) {

  // eslint-disable-next-line
  const { t } = useTranslation()

   // @ts-ignore
  const [state] = useContext(Context);
  const [covertura, setcovertura] = useState({});

  useEffect(() => {
    $('.content_steaper').css('opacity', '1')
    $('.step-thanks').css('opacity', '1')
   
    if (ready) {
      localStorage.setItem('InfoRequest', JSON.stringify(state.inputs))
      setTimeout(() => {     
        CreateReq()
      }, 500);
    }
  }, [ready])

  const gotourl = () =>  {
    window.location.href = 'https://www.emilan.co'
  }

  // // // // // // // // // // // // // // // // // // // // // //
  //                                                             //
  //                          REQUEST                            //
  //                                                             //
  // // // // // // // // // // // // // // // // //  //  // //  //
  //             Creation Full Request !!! important             //
  // // // // // // // // // // // // // // // // //  //  // //  //

  const CreateReq = async () => {

    let inputs = (JSON.parse(localStorage.getItem('InfoRequest')))

    async function asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    }

    // Update User 
    let user_body = {
      "country_birth": inputs.country_birth,
      "uscis": inputs.uscis && inputs.uscis !== '' ? inputs.uscis : 0,
      "status_migrate": inputs.status_migrate,
      "heght": inputs.heght,
      "weight": inputs.weight+'lbs' ,
      "name_work": inputs.name_work,
      "ocupation_work": inputs.ocupation_work,
      "preference_lang": localStorage.getItem('lng'),
      "heritage": inputs.heritage,
      "smoker": inputs.smoker,
      "nro_license": inputs.nro_license-0,
      "lic_state": inputs.lic_state,
      "lic_active": inputs.lic_active,
      "health_status": inputs.health_status,
      "health_family": inputs.health_family,
      "provision_med": inputs.provision_med,
      "provision_exam": inputs.provision_exam,
      "Income": inputs.income,
      "credit_rating": inputs.credit_rating,
      "coverage_importance": inputs.coverage_importance,
      "ITIN": inputs.ITIN && inputs.ITIN !== '' ? inputs.ITIN : 0
    }
    await getinApi('put', 'users/' + inputs.user_id, user_body).then((res) => {  /* console.log(res); */ }).catch((err) => console.log(err))

    // Info_dr 
    let info_dr = inputs.info_dr
    if (info_dr !== '0'){
      let info_body = {
        "name": info_dr.name_doc,
        "city": info_dr.city_doc,
        "state": info_dr.state_doc,
        "last_visit": info_dr.year_doc + '-' + info_dr.month_doc + '-01',
        "user_id": inputs.user_id
      }
      await getinApi('post', 'infodrs', info_body).then((res) => { /* console.log(res); */ }).catch((err) => console.log(err))
    }

    // Coverage
    let coverage = inputs.coverage
    setcovertura(coverage)
    if (coverage){
      let coverage_body = {
        "price": coverage.price,
        "term": coverage.term-0,
        "coverage": coverage.coverage,
        "genre": coverage.face_amt,
        "period": coverage.period,
        "plan_id": 1
      }
  
      await getinApi('post', 'coverages', coverage_body).then( async (res) => { 
        /* console.log(res); */ 
        // Request 
        let request_body = {
          "IUL": "Complete",
          "face_amt": coverage.price,
          "date_request": inputs.finacial.date_year + '-' + inputs.finacial.date_month + '-' + inputs.finacial.date_day,
          "coverage_id": res.coverage.id,
          "user_id": inputs.user_id 
        }
        await getinApi('post', 'requests', request_body).then((res) => { /* console.log(res); */ }).catch((err) => console.log(err))
      }).catch((err) => console.log(err))
    }

    // Diagnostics 
    let diagnostics = [inputs.diagnostics_p, inputs.diagnostics_a]
    if (diagnostics){
      diagnostics.forEach(diagnostic => {
        asyncForEach(diagnostic , async (diag) => {
          let diagnostic_body = {
            "name": diag.name,
            "date": diag.date_year + '-' + diag.date_moth +'-01',
            "user_id": inputs.user_id 
          }
          await getinApi('post', 'diagnostics', diagnostic_body).then((res) => { 
            /* console.log(res); */ 
            if (diag.medicines.length) {
              asyncForEach(diag.medicines, async (medicine) => {
                let medicine_body = {
                  "name": medicine.name,
                  "dose": medicine.dose,
                  "frequency": medicine.frequency,
                  "date": diag.date_year + '-' + diag.date_moth + '-01',
                  "diagnostic_id": res.diagnostic.id,
                  "user_id": inputs.user_id 
                }
                await getinApi('post', 'medicines', medicine_body).then((res) => { /* console.log(res); */ }).catch((err) => console.log(err))
              });
            }
          }).catch((err) => console.log(err))
        })
      });
    }


    // Beneficiaries
    let beneficiaries = inputs.beneficiaries
    if (beneficiaries){
      asyncForEach(beneficiaries, async (bene) => {
        let bene_body ={
          "name": bene.name,
          "s_name": bene.s_name,
          "relationship": bene.relationship,
          "birthday": bene.birthday, 
          "porcent": bene.porcent-0,
          "user_id": inputs.user_id 
        }
        await getinApi('post', 'beneficiaries', bene_body).then((res) => { /* console.log(res); */ }).catch((err) => console.log(err))
      });
    }

    // Parents
    let parents = inputs.parents
    if (parents){
      asyncForEach(parents, async (parent) => {
        let parent_body = {
          "relationship": parent.relationship,
          "age": (parent.age) ? parent.age-0 : 0,
          "death": parent.death-0,
          "death_cause": parent.death_cause,
          "user_id": inputs.user_id 
        }
        await getinApi('post', 'parents', parent_body).then((res) => { /* console.log(res); */ }).catch((err) => console.log(err))
      });
    }

    // Policies  
    let policies = inputs.policies
    if (policies.length > 0 && policies[0].company){
      asyncForEach(policies, async (policy) => {
        let policy_body = {
          "company": policy.company,
          "nro_policy": policy.nro_policy,
          "coverage": policy.coverage,
          "date_afiliation": policy.date_afiliation_year+'-'+policy.date_afiliation_month+'-01',
          "valid": policy.valid-0,
          "user_id": inputs.user_id 
        }
        await getinApi('post', 'policies', policy_body).then((res) => { /* console.log(res); */ }).catch((err) => console.log(err))
      });
    }

    // Financials
    let financials = inputs.finacial
    if (financials){
      let financial_body = {
        "name_bank": financials.preference === '0'  ? financials.name_bank : 'NO DATA',
        "nr_rut": financials.preference === '0' ? financials.nr_rut : 'NO DATA',
        "nr_account": financials.preference === '0' ? financials.nr_account : 'NO DATA',
        "preference": financials.preference,
        "signature_digital": financials.signature_digital,
        "signature_type": financials.signature_type,
        "user_id": inputs.user_id 
      }
      await getinApi('post', 'financials', financial_body).then((res) => { 
        /* console.log(res); */ 
        window.analytics.track("Requests Status", {
          status: 'Complete',
          attempt_id: Date.now()
        });
      }).catch((err) => console.log(err))
    }
  }

  return (
    <div className="content_box thanks view">
      <div className="load_ing">
          <div>
            Loading...
          </div>
      </div>
      <Steper step={6} />
      <div className="step-thanks">
        <h2 className='center h2'>{t('felicidades.label')}</h2>
        <div className='flx contenedor-thanks'>
          <div className="backimage img25 img-thanks">
            <div className='img'>
              <img className='center' src={"images/img-step25.svg"} alt="img-step25" />
            </div>
          </div>
          <div className='contenido-thanks'>
            <div className='center h3'>
              {t('hasaplicadoa.label')}
              {covertura.term}
              {t('hasaplicadob.label')}
              {covertura.face_amt},000
            </div>
            <div className='flx valor cobertura'>
              <img className='img-info' src={"images/iconos1.svg"} alt='icon'></img>
              <p className='p'>{t('sipasaalgo.label')}{covertura.face_amt}{t('sipasaalgo2.label')}</p>
            </div>
            <div className='flx años cobertura'>
              <img className='img-info' src={"images/iconos2.svg"} alt='icon'></img>
              <p className='p'>{t('tusserespermaneceran.label')}{covertura.term}{t('tusserespermaneceran2.label')}</p>
            </div>
            <div className='center h4'>{t('prontotecontactan.label')}</div>
            <div className='center'>
              <button className=' center boton btn-2' onClick={gotourl} type='submit' value='salir'>{t('salir.label')}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}