import React, { useState } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Step14({ dispathInputs, changeStep, setError }) {

    // eslint-disable-next-line
  const { t } = useTranslation()

  const [inputs, setInputs] = useState({
    heritage: 0,
    income: 0
  })

  const setInput = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }
  const submitInputs = e => {
    e.preventDefault();

    let validations = 0;
    if (inputs.heritage === 0 || isNaN(inputs.heritage) || (inputs.heritage).trim() === '' || (inputs.heritage).length === 0  ) {
      $('.inp-heritage').addClass('invalide')
      validations = 1;
    }
    if (inputs.income === 0 || isNaN(inputs.income) || (inputs.income).trim() === '' || (inputs.income).length === 0 ) {
      $('.inp-income').addClass('invalide')
      validations = 1;
    }

    if (validations) {
      setError(true)
      return
    }

    dispathInputs(inputs)
    changeStep(15)
  } 

  return (
    <div className="step-content step-14">
      <div className="backimage img14">
        <div className='img'>
          <img className='center' src={"images/img-step14.svg"} alt="img-step14" />
        </div>
      </div>
      <h2 className='center h2'>{t('ahoraneces.label')}</h2>
      <div className='form-st14 form_'>
        <div className='flx'>
          <input className='input inp-income' type="text" name='income' onChange={setInput} placeholder={t('ingresoanual.label')} />
        </div>
        <div className='patrimonio-st14'>
          <label className='h2'>
            {t('patrimonio.label')}             
            <div className="help-tip" role="tooltip" id="name-tooltip">
              <div className="help-tip__content st1-4">
                <h3 className="help-tip__title">{t('eslarestat.label')}</h3>
                <p>
                  {t('eslaresta.label')}
                </p>
                <button className='boton' type='submit' id="close-help-tip" value='continuar'>{t('entendido.label')}</button>
              </div>
            </div>
          </label>
          <div className='flx'>
            <input className='input inp-heritage' type="text" name='heritage' onChange={setInput} placeholder='$' />
          </div>
        </div>
        </div>
        <button className='boton' onClick={submitInputs} value='continuar'>{t('continuar.label')}</button>
    </div>
  )
}