import React from 'react'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Step6({ dispathInputs, changeStep, setError}) {

    // eslint-disable-next-line
  const { t } = useTranslation()

  const setInput = e => {
    e.preventDefault();
    dispathInputs({ [e.target.name]: e.target.value })
    nextStep()
  }
  const nextStep = () => {
    changeStep(7)
  }  

  return (
    <div className="step step-content step-6">
      <div className="backimage img6">
        <div className='img'>
          <img className='center' src={"images/img-step6.svg"} alt="img-step6" />
        </div>
      </div>
      <h2 className='center h2' aria-describedby="name-tooltip">
        {t('entemassalud.label')}
        <div className="help-tip" role="tooltip" id="name-tooltip">
          <div className="help-tip__content">
            <h3 className="help-tip__title">{t('seleccionarespuesta.label')}</h3>
            <ul>
              <li>
                <b>{t('muysaludt.label')}</b>
                {t('muysalud.label')}
              </li>
              <li>
                <b>{t('bastantesaludt.label')}</b>
                {t('bastantesalud.label')}
              </li>
              <li>
                <b>{t('promediotienest.label')}</b>
                {t('promediotienes.label')}
              </li>
            </ul>
            <button className='boton' type='submit' id="close-help-tip" value='continuar'>{t('entendido.label')}</button>
          </div>
        </div>
      </h2>
      <div className='flx form-st6 form_'>
        <button className={'boton ' + t('lang.label')} onClick={setInput} name="health_status" value='Muy Saludable'>{t('muy.label')}</button>
        <button className={'boton ' + t('lang.label')} onClick={setInput} name="health_status" value='Bastante Saludable'>{t('bastante.label')}</button>
        <button className={'boton ' + t('lang.label')} onClick={setInput} name="health_status" value='Promedio'>{t('promedio.label')}</button>
      </div>
    </div>
  )
}