import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import $ from 'jquery'

// Trasnlation 
import './../../i18n'
import { useTranslation } from 'react-i18next'

export default function Step1({ dispathInputsBene, changeStep, setError }) {

  // eslint-disable-next-line
  const { t } = useTranslation()

  const [generalIds, setIds] = useState(1)
  const [beneficiaries, setBenes] = useState([])
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    if (beneficiaries.length <= 0){
      setNewBene()
    }
  }, [])

  const setRemoveBene = bene => {
    // console.log(bene);
    if(beneficiaries.length > 1 ){
      $('body').find('.invalide').removeClass('invalide')
      let local_bene = [...beneficiaries]
      let newBene = local_bene.filter(function (data, i) {
        return data.id !== bene 
      });
      setBenes(newBene)
    }
  }

  const setNewBene = e => {
    if(beneficiaries.length < 4 ){
      $('body').find('.invalide').removeClass('invalide')
      setError(false)
      let newBene = {
        'id': generalIds,
        'name': '',
        's_name': '',
        'relationship': '',
        'birthday': '',
        'porcent': 0
      }
      setBenes([...beneficiaries, newBene])
      setIds(generalIds + 1)
    }
  }

  const setInputBene = (e, bene) => {
 
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    if (moment.isDate(e)) {
      setStartDate(moment(e).toDate());
      var date_value = moment(e).format('YYYY-MM-DD')
      e = { target: { name: '' , value: ''}}
      e.target.name = 'birthday'; 
      e.target.value = date_value; 
    }
    let local_bene = [...beneficiaries]
    local_bene.filter(function (data, i) {
      if (data.id === bene) {
        data[e.target.name] = e.target.value
      }
      return false
    });
    setBenes(local_bene)
  }

  const submitInputs = () => {
    var validations = 0;
    let porcent_total = 0; 
    beneficiaries.forEach(bene => {
      if (bene.name === null || bene.name === '') {
        $('.inp-name' + bene.id).addClass('invalide')
        validations = 1;
      }
      if (bene.s_name === null || bene.s_name === '') {
        $('.inp-s_name' + bene.id).addClass('invalide')
        validations = 1;
      }
      if (bene.relationship === null || bene.relationship === '') {
        $('.inp-relationship' + bene.id).addClass('invalide')
        validations = 1;
      }
      if (bene.birthday === null || bene.birthday === '') {
        $('.inp-birthday' + bene.id).addClass('invalide')
        validations = 1;
      }
      if (bene.porcent === null || bene.porcent === '' || bene.porcent === 0 || bene.porcent === '0') {
        $('.inp-porcent' + bene.id).addClass('invalide')
        validations = 1;
      }
      porcent_total = porcent_total + ( parseInt(bene.porcent) ); 
    });
    if (porcent_total !== 100 ) {
      $('.inp-porcent1').addClass('invalide')
      $('.inp-porcent2').addClass('invalide')
      $('.inp-porcent3').addClass('invalide')
      $('.inp-porcent4').addClass('invalide')
      validations = 1;
    }

    if (validations) {
      setError(true)
      return
    }

    dispathInputsBene({ 'beneficiaries': beneficiaries })
    nextStep()
  } 

  const nextStep = () => {
    changeStep(2)
  }

  return (
    <div className="step-content step-1-beneficiaries">
      <h2 className='center h2'>{t('sialgotepasa.label')}</h2>
      <div className ="backimage img21">
        <div className='img'>
          <img className='center'  src={"images/img-step21.svg"} alt="img-step21"/>
        </div>
      </div>
      { (beneficiaries.length > 0) && beneficiaries.map((bene, index) => 
        <div className='flx form-beneficiarios' key={index + 'bene' + bene.id }>
          <div className='nombre-benef info'>
            <label className='label'>
              {t('nombre.label')}
            </label>
            <div className='flx'>
              <input className={'input inp-name' + bene.id} type="text" name='name' onChange={(e) => setInputBene(e, bene.id)} defaultValue={bene.name} />
            </div>
          </div>
          <div className='apellido-benef info'>
            <label className='label'>
              {t('apellido.label')}
            </label>
            <div className='flx'>
              <input className={'input inp-s_name' + bene.id} type="text" name='s_name' onChange={(e) => setInputBene(e, bene.id)} defaultValue={bene.s_name} />
            </div>
          </div>
          <div className='parentesco-benef info'>
            <label className='label'>
              {t('parentesco.label')}
            </label>
            <div className='flx'>
              <input className={'input inp-relationship' + bene.id} type="text" name='relationship' onChange={(e) => setInputBene(e, bene.id)} defaultValue={bene.relationship} />
            </div>
          </div>
          <div className='birthdate-benef info'>
            <label className='label' >
              {t('fechnacimi.label')}
            </label>
            <div className='flx'>
              <DatePicker
                className={"input inp-birthday" + bene.id}
                selected={startDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                name="birthday" onChange={(e) => setInputBene(e, bene.id)}
              />
            </div>
          </div>
          <div className='porcentaje-benef info'>
            <label className='label ' htmlFor="porcentaje-benef">
              {t('porcentaje.label')}
            </label>
            <div className='flx'>
              <input className={'input inp-porcent' + bene.id} id='porcentaje-benef' type="number" name='porcent' placeholder={t('ejemporce.label')} onChange={(e) => setInputBene(e, bene.id)} defaultValue={bene.porcent}></input>
            </div>
          </div>
          {(index !== (beneficiaries.length - 1) || beneficiaries.length === 4) && <div className='icono-2 in-x' onClick={(e) => setRemoveBene(bene.id)}>x</div> }
          {( index === (beneficiaries.length - 1) && beneficiaries.length < 4 )  && <div className='icono-2' onClick={setNewBene}>+</div> }
        </div> 
      )}
      <button className=' center boton btn-2' type='submit' value='continuar' onClick={submitInputs}>{t('continuar.label')}</button>
      <footer>
        <p>{t('explicporcent.label')}</p>
      </footer>
    </div>
  )
}