import React, { useState } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Step1({ dispathInputs, changeStep, setError }) {
  // eslint-disable-next-line
  const { t } = useTranslation()
  
  const [inputs, setInputs] = useState({
    name: '',
    s_name: ''
  })

  const setInput = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }
  const submitInputs = () => {
    let validations = 0;
    if ((inputs.name).trim() === '') {
      $('.inp-nombre').addClass('invalide')
      validations = 1;
    } 
    if((inputs.s_name).trim() === '') {
      $('.inp-apellido').addClass('invalide')
      validations = 1;
    }

    if (validations){
      setError(true)
      return
    }

    dispathInputs(inputs)
    $('.sp-arrow.sp-ar').attr('style', 'display:block;');
    $('.sp-arrow.sp-ar').addClass('animate__animated animate__backInLeft')
    changeStep(2)
  } 

  return (
    <div className="step step-content step-1">
      <div className ="backimage img1">
        <p className='center'>{t('holavamos.label')}</p>
        <div className='img'>
          <img className='center'  src={"images/img-step1.svg"} alt="img-step1"/>
        </div>
      </div>
      <h2 className='center h2'>{t('aquin.label')}</h2>
      <div className='form-st1 form_'>
        <input className='input inp-nombre' type='text' name='name' placeholder={t('nombre.label')} onChange={setInput} />
        <input className='input inp-apellido' type='text' name='s_name' placeholder={t('apellido.label')} onChange={setInput} />
      </div>
      <button className='boton' onClick={submitInputs} type='submit' value='continuar'>{t('continuar.label')}</button>
    </div>
  )
}  