import React, { useState, useEffect } from 'react'
import Steper from '../components/stepper'
import moment from "moment";
import $ from 'jquery'
import SignatureCanvas from 'react-signature-canvas'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Financial({ dispathInputs, changeStep, ChangeReady }) {

  const [prefence, setPref] = useState(false)
  const [error, setError] = useState(false);
  const [inputs, setInputs] = useState({
    name_bank: '',
    nr_rut: '',
    nr_account: '',
    preference: '0',
    signature_digital: '',
    signature_type: '',
    date_month:'',
    date_day:'',
    date_year:'',
  })

  useEffect(() => {
    getSign()
    setDates()
  }, [])

  const clearInvalid = () => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
  }

  const setDates = () => {
    var check = moment(new Date(), 'YYYY/MM/DD');
    var dater = {
      month: check.format('MM'),
      day: check.format('DD'),
      year: check.format('YYYY')
    }
    setInputs({ ...inputs, date_month: dater.month, date_day: dater.day, date_year: dater.year})
  }

  const setInput = e => {
    clearInvalid()
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }

  const checkNot = (p) => {
    clearInvalid()
    if (p) {
      $('.hider').css('opacity', '0.3')
      $('.hider').find('input').val('')
      $('.hider').find('input').attr('disabled', 'true')
      setInputs({ ...inputs, name_bank: '', nr_rut: '', nr_account: '', preference: '1' })
      setPref(true)
    } else {
      $('.hider').css('opacity', '1')
      $('.hider').find('input').removeAttr('disabled')
      setInputs({ ...inputs, name_bank: '', nr_rut: '', nr_account: '', preference: '0' })
      setPref(false)
    }
  }
  
  const getFile = e => {
    clearInvalid()
    setInputs({ ...inputs, signature_digital: '', signature_type: '' })
    $('#fileinput').trigger('click'); 
    $('.opt-1').show(); 
    $('.opt-2').hide(); 
    $('.opt-3').hide(); 
  }
  const getText = e => {
    clearInvalid()
    setInputs({ ...inputs, signature_digital: $('#text_sign').val(), signature_type: 'Text' })
    $('.opt-1').hide(); 
    $('.opt-2').hide(); 
    $('.opt-3').show(); 
  }
  const getSign = e => {
    clearInvalid()
    setInputs({ ...inputs, signature_type: 'Sign' })
    trim()
    $('.opt-1').hide(); 
    $('.opt-2').show(); 
    $('.opt-3').hide(); 
  }

  let sigPad = {}

  const clearSig = () => {
    clearInvalid()
    setInputs({ ...inputs, signature_digital: '', signature_type: 'Sign' })

    sigPad.clear();
  }

  const trim = () => {
    clearInvalid()
    setInputs({ ...inputs, signature_digital: sigPad.getTrimmedCanvas().toDataURL() , signature_type: 'Sign' })
  }

  const getImage = (e) => {
    clearInvalid()
    if (e.target.files[0]){
      setInputs({ ...inputs, signature_type: 'Image' })
      var reader = new FileReader();
      var f = e.target.files[0]
      reader.onloadend = function () {
        setInputs({ ...inputs, signature_digital: reader.result,  signature_type: 'Image' })
      }
      reader.readAsDataURL(f);
    }
  }
  const submitInputs = () => {
    let validations = 0;

    if (!prefence) {
      if ((inputs.name_bank).trim() === '') {
        $('#name-banco').addClass('invalide')
        validations = 1;
      }
      if ((inputs.nr_rut).trim() === '') {
        $('#num-ruta').addClass('invalide')
        validations = 1;
      }
      if ((inputs.nr_account).trim() === '') {
        $('#num-cuenta').addClass('invalide')
        validations = 1;
      }
    }

    if ((inputs.signature_digital).trim() === '') {
      $('#firma-id').addClass('invalide')
      $('#sig-buton-cap').addClass('invalide')
      validations = 1;
    }
    if ((inputs.signature_type).trim() === '') {
      $('#firma-id').addClass('invalide')
      validations = 1;
    }

    if (validations) {
      setError(true)
      return
    }

    dispathInputs({ 'finacial': inputs })
    ChangeReady()
    nextStep()
  }
  
  const nextStep = () => {
    changeStep(21)
  }  

  // eslint-disable-next-line
  const { t } = useTranslation()

  return (
    <div className="content_box financiala view">
      <Steper step={5} />
      <div className="step-financial">
        <h2 className='center h2'>{t('paragenerarpago.label')}</h2>
        <div className='financial'>
          <div className="backimage img24 img-financial">
            <div className='img'>
              <img className='center' src={"images/img-step24.svg"} alt="img-step24" />
            </div>
          </div>
          <div className='contenido-financial'>
            <div className='flx form-financial'>
              <div className='nombre-banco info hider'>
                <label className='label' htmlFor="name-banco">{t('banco.label')}</label>
                <div className='flx'>
                  <input className='input' id='name-banco' type="text" name='name_bank' onChange={setInput} />
                </div>
              </div>
              <div className='numero-ruta info hider'>
                <label className='label' htmlFor="num-ruta">{t('numruta.label')}</label>
                <div className='flx'>
                  <input className='input' id='num-ruta' type="number" name='nr_rut' onChange={setInput} />
                </div>
              </div>
              <div className='numero-cuenta info hider'>
                <label className='label' htmlFor="num-cuenta">{t('numcuenta.label')}</label>
                <div className='flx'>
                  <input className='input' id='num-cuenta' type="number" name='nr_account' onChange={setInput} />
                </div>
              </div>
              <div className='check-call info'>
                <label className='label checkbox' htmlFor="info-call" >
                  <input id='info-call' type='checkbox' name='preference' onClick={() => checkNot(!prefence) } />
                  <span className="checkmark"></span>
                  {t('prefiersuminis.label')}
                </label>
              </div>
              <div className='firma info'>
                <label className='label ' htmlFor="firma-id">{t('firmaaplicante.label')}</label>
                <div className='flx'>
                  <div className='textarea' id='firma-id' >
                    <div className="opt-1">
                      {inputs.signature_type === 'Image' && <img src={inputs.signature_digital} className="img_signa" /> }
                      <input className="input" type="file"  name="signature" id="fileinput" onChange={getImage} accept=".png, .jpg, .jpeg" /> 
                    </div>
                    <div className="opt-2 signatur" >
                      <SignatureCanvas penColor='black' canvasProps={{ width: 383, height: 69, className: 'sig-canvas' }} ref={(ref) => { sigPad = ref }} />
                      <div className="sig-buton">                   
                        <button onClick={clearSig}>{t('limpiar.label')}</button>
                        <button id="sig-buton-cap" onClick={trim}>{t('capturar.label')}</button>
                      </div>
                      <span>* para guardar la firma dale en capturar</span>
                    </div>
                    <div className="opt-3">
                      <textarea placeholder="Firma digital" name="signature_digital" id="text_sign" onChange={setInput}></textarea>
                    </div>
                    <div className="brns">
                      <button className="btn1 brn" onClick={getText} >
                        <img src="images/tex.png" />
                      </button>
                      <button className="btn2 brn" onClick={getSign}>
                        <img src="images/pen.png" />
                      </button>
                      <button className="btn3 brn" onClick={getFile}>
                        <img src="images/cam.png" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='fecha-aplicacion info'>
                <label className='label ' htmlFor="fecha-aplic" onChange={setInput}>{t('fechaplic.label')}</label>
                <div className="date">
                  {inputs.date_month !== '' && 
                    <select className='input mes-aplic' id="mes-aplic" name="date_month" defaultValue={inputs.date_month} onChange={setInput} disabled>
                      <option className='opt' value="DEFAULT" disabled>Mes</option>
                      <option value="01"> {t('enero.label')}</option>
                      <option value="02"> {t('febrero.label')}</option>
                      <option value="03"> {t('marzo.label')}</option>
                      <option value="04"> {t('abril.label')}</option>
                      <option value="05"> {t('mayo.label')}</option>
                      <option value="06"> {t('junio.label')}</option>
                      <option value="07"> {t('julio.label')}</option>
                      <option value="08"> {t('agosto.label')}</option>
                      <option value="09"> {t('septiembre.label')}</option>
                      <option value="10"> {t('octubre.label')}</option>
                      <option value="11"> {t('noviembre.label')}</option>
                      <option value="12"> {t('diciembre.label')}</option>
                    </select>}
                  {inputs.date_day !== '' && 
                    <select className='input dia-aplic' id="dia-aplic" name="date_day" defaultValue={inputs.date_day} onChange={setInput} disabled>
                      <option value="DEFAULT" disabled>Dia</option>
                      <option value="01">1</option>
                      <option value="02">2</option>
                      <option value="03">3</option>
                      <option value="04">4</option>
                      <option value="05">5</option>
                      <option value="06">6</option>
                      <option value="07">7</option>
                      <option value="08">8</option>
                      <option value="09">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                    </select>}
                  <input className='input year-aplic' id="year-aplic" name="date_year" type="text" placeholder="Year" defaultValue={inputs.date_year} onChange={setInput} disabled/>
                </div>
              </div> 
            </div>
            <div className='center'>
              <button className='center boton btn-2' type='submit' value='someter' onClick={submitInputs} >Someter</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}