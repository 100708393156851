import React, { useState, useContext } from 'react'
import { Context } from '../services/store'
import { Circles } from 'svg-loaders-react';
import $ from 'jquery'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

// Services
import { getinApi } from '../services/api'

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default function Step5({ dispathInputs, changeStep, setError }) {

    // eslint-disable-next-line
  const { t } = useTranslation()
  // @ts-ignore
  const [state] = useContext(Context);
  const [inputs, setInputs] = useState({
    email: '',
    phone: ''
  })
  const [terms, setTerms] = useState(false)
  const [termsError, setTermsError] = useState(false)

  const setInput = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }
  const submitInputs = () => {
    let validations = 0;
    if ((inputs.email).trim() === '' || !validateEmail(inputs.email) ) {
      $('.inp-email').addClass('invalide')
      validations = 1;
    }
    if ((inputs.phone).trim() === '' || (inputs.phone).length < 10  ) {
      $('.inp-cel').addClass('invalide')
      validations = 1;
    }

    if (validations) {
      setTermsError(false)
      setError(true)
      return
    }
    
    if(terms){
      dispathInputs(inputs)
      CreateUser()
    }else{
      setTermsError(true)
    }
  } 
  const setTerm = () => {
    setTerms(!terms)
  } 

  const CreateUser = async () => {
    let user = state.inputs
    $('.laoder-user').attr('style','display:flex;')
    await getinApi('post', 'users', user)
      .then((res) => {
        $('.laoder-user').attr('style', 'display:none;')
        // console.log(res);
        if (res.Message){
          dispathInputs({ user_id: res.id })
          window.analytics.track("Users Status", {
            status: 'Returned',
            attempt_id: Date.now()
          });
        }else{
          user = res.user.id
          dispathInputs({ user_id: res.user.id} )
          window.analytics.track("Users Status", {
            status: 'New',
            attempt_id: Date.now()
          });
        }
      })
      .catch((err) => console.log(err))
    changeStep(6)
  }

  return (
    <div className="step-content step-5">
      <div className="laoder-user">      
        <Circles stroke="#fff" fill="#17428E" width="90" height="90" strokeOpacity=".125"/>
        <span>Cargando...</span>
      </div>
      <div className="backimage img5">
        <div className='img'>
          <img className=' center img-5' src={"images/img-step5.svg"} alt="img-step5" />
        </div>
        <div className='h2-mobile'>
          <h2 className='center h2'>{t('perfecto.label')}</h2>
          <p>{t('necesitamostu.label')}</p>
      </div>
      </div>
      <div className='contenido-step5'>

        <h2 className='center h2'>{t('perfecto.label')}</h2>
        <p>{t('necesitamostu.label')}</p>

        <div className='flx form_'>
          <input className=' input inp-email' type='email' name='email' placeholder={t('correo.label')} onChange={setInput} />
          <input className=' input inp-cel' type='tel' name='phone' placeholder={t('numcell.label')} onChange={setInput} />
          <label className='checkbox terminos' htmlFor="acepto">
            <input id='acepto' type='checkbox' name='terms' defaultValue={terms} onClick={setTerm}/>
            <span className="checkmark"></span>
            {t('reconozcoque.label')}
          </label>
        </div>
        <button className='boton' type='submit' onClick={submitInputs} value='continuar'>{t('continuar.label')}</button>
        <footer>
        {t('comprendes.label')}
        </footer>
        {termsError && <span className="error_label">Acepta los Terminos</span>}
      </div>
    </div>
  )
}