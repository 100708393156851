import React from 'react'

export default function Header() {
  return (
    <div className="header">
      <div className="source-logo">
        <img src="./images/logo-emilan.svg" alt="Logo Emilan" />
      </div>
    </div>
  )
}