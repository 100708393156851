import React, { useState, useEffect } from 'react'

// Services
import { getinApi } from '../services/api'
import Steper from '../components/stepper'

export default function Pro() {

  let [cities, setCities] = useState([])

  useEffect(() => {
    getCities()
  }, [])

  const getCities = async () =>{
    await getinApi('get', 'citys')
      .then((res) => { 
        // console.log(res)
        setCities(res.cities)
      })
      .catch((err)=> console.log(err))
  }
  const getCity = async () => {
    await getinApi('get', 'citys/2')
      .then((res) =>{ 
        // console.log(res);
      })
      .catch((err) => console.log(err))
  }
  const CreateCity = async () =>{
    let city = { "name": "Cartagena" , "short": "CRT"  }
    await getinApi('post', 'citys', city)
      .then((res) =>{ 
        // console.log(res);
        getCities();
      })
      .catch((err)=> console.log(err))
  }
  const ModiCity = async () =>{
    let city = { "name": "Santander" , "short": "SA" }
    await getinApi('put', 'citys/6', city)
      .then((res) =>{ 
        // console.log(res);
        getCities();
      })
      .catch((err)=> console.log(err))
  }
  const DeleteCity = async () =>{
    await getinApi('delete', 'citys/2' )
      .then((res) =>{ 
        // console.log(res);
        getCities();
      })
      .catch((err)=> console.log(err))
  }


  return (
    <div className="content_box">
      <Steper step={3} />
      <ul>
        {cities && cities.map((city) =>
          <li key={city.id}>
            {city.name}
          </li>
        )}
        <button onClick={getCities}>Carga Todo</button>
        <button onClick={getCity}>Carga Uno</button>
        <button onClick={CreateCity}>Crear</button>
        <button onClick={ModiCity}>Modificar</button>
        <button onClick={DeleteCity}>Eliminar</button>
      </ul>
    </div>

  )
}


