import React, { useState, useEffect } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

// File Conditions
import Months_es from "../assets/files/months_es.json";
import Months_en from "../assets/files/months_en.json";


export default function Step4({ dispathInputs, changeStep, setError }) {

    // eslint-disable-next-line
  const { t } = useTranslation()
  let [selectMonth, setSelMonth] = useState()
  let [selectDay, setSelDay] = useState()
  let [days, setDays] = useState([])
  let [months, setMonths] = useState([])

  const [inputs, setInputs] = useState({
    dbd: '',
    mbd: '',
    ydb: 0
  })

  useEffect(() => {
    if (localStorage.getItem('lng') === 'es') {
      setMonths(Months_es)
    } else {
      setMonths(Months_en)
    }
  }, [])


  const setSelectMonth = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    let day_s = [] 
    let daym = 32;
    if (e.value === '02' ){
      daym = 29;
    }
    for (let day = 1; day < daym ; day++) {
      if( day < 10 ){
        day_s.push( { value: '0'+day,label:day })
      }else{
        day_s.push({ value: day, label: day })
      }
    }
    setSelMonth(e)
    setDays(day_s)
    setInputs({ ...inputs, mbd: e.value })
  }
  
  const setSelectDay = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    setSelDay(e)
    setInputs({ ...inputs, dbd: e.value })
  }

  const setInput = e => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }

  const submitInputs = () => {
    let validations = 0;
    if ((String(inputs.dbd)).trim() === '') {
      $('#dia').addClass('invalide')
      validations = 1;
    }
    if ((String(inputs.mbd)).trim() === '') {
      $('#mes').addClass('invalide')
      validations = 1;
    }

    if ((inputs.ydb) === 0 || (inputs.ydb).length > 4 || (inputs.ydb).length < 4 || isNaN(inputs.ydb) || (inputs.ydb).length === 0) {
      $('#year').addClass('invalide')
      validations = 1;
    }
    
    if (validations) {
      setError(true)
      return
    }

    let birthday = { birthday: inputs.ydb + '-' + inputs.mbd + '-' + inputs.dbd }
    dispathInputs(birthday)
    changeStep(5)
  }

  return (
    <div className="step step-content step-4">
      <div className="backimage img4">
        <div className='img'>
          <img className='center' src={"images/img-step4.png"} alt="img-step4" />
        </div>
      </div>
      <h2 className='center h2'>{t('fechanacimiento.label')}</h2>
      <div className="form_">
        <div className="flx select-bday">
          <Select className='slect-tag mes' id="mes" classNamePrefix="react-select" options={months} value={selectMonth} onChange={setSelectMonth} placeholder={t('mes.label')} />
          <Select className='slect-tag dia' id="dia" classNamePrefix="react-select" options={days} value={selectDay} onChange={setSelectDay} placeholder={t('dia.label')}/>
          <input className='input year' id='year' type="text" onChange={setInput} name='ydb' placeholder={t('año.label')} />
        </div>
        <button className='boton' onClick={submitInputs} type='submit' value='continuar'>{t('continuar.label')}</button>
      </div>
    </div>
  )
}

  