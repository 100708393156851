import React from 'react'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Step16({ dispathInputs, changeStep, setError }) {

    // eslint-disable-next-line
  const { t } = useTranslation()

  const setInput = e => {
    e.preventDefault();
    dispathInputs({ [e.target.name]: e.target.value })
    nextStep()
  }

  const nextStep = () => {
    changeStep(17)
  }  

  return (
    <div className="step-content step-16">
      <div className="backimage img16">
        <div className='img'>
          <img className='center' src={"images/img-step16.svg"} alt="img-step16" />
        </div>
      </div>
      <h2 className='center h2'>{t('queesmas.label')}</h2>
      <div className='form-st16 form_'>
        <div className='flx btns_st16'>
          <button className='boton a' type='submit' onClick={setInput} name="coverage_importance" value='Mensual'>{t('montomes.label')}</button>
          <button className='boton b' type='submit' onClick={setInput} name="coverage_importance" value='Total'>{t('montototal.label')}</button>
        </div>
      </div>
    </div>
  )
}