import React from 'react'
import $ from 'jquery'
// Trasnlation 
import '../../i18n'
import { useTranslation } from 'react-i18next'



const hideNext = () => {
  $('.stepHome-add').addClass('animate__animated animate__slideOutLeft')
  setTimeout(() => {
    $('.stepHome-add').hide();
  }, 500)
}

export default function Home() {

  // eslint-disable-next-line
  const { t } = useTranslation()
  
  return (
    <div className="stepHome-add">
      <h2 className='center h2'>{t('continuemos.label')}</h2>
      <div className='flx contenedor'>
        <div className="backimage img17 imgHome-add">
          <div className='img'>
            <img className='center' src={"images/img-step17.svg"} alt="img-step17" />
          </div>
        </div>
        <div className='contenido-home'>
          <div className='center h2'>{t('faltapoc.label')}</div>
          <div className='center h3'>{t('nuestrasaseg.label')}</div>
          <div className="btns">
            <button className='center boton btn-2' type='submit' value='continuar' onClick={hideNext}>{t('continuar.label')}</button>
          </div>
        </div>
      </div>
    </div>
  )
}
