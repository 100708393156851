import React from 'react'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Step15({ dispathInputs, changeStep, getCalc, setError}) {

    // eslint-disable-next-line
  const { t } = useTranslation()

  const setInput = e => {
    e.preventDefault();
    dispathInputs({ [e.target.name]: e.target.value })
    getCalc()
    changeStep(16)
  }

  return (
    <div className="step-content step step-15">
      <div className="backimage img15">
        <div className='img'>
          <img className='center' src={"images/img-step15.svg"} alt="img-step15" />
        </div>
      </div>
      <h2 className='center h2'>{t('cualestu.label')}
        <div className="help-tip" role="tooltip" id="name-tooltip">
          <div className="help-tip__content">
            <h3 className="help-tip__title">{t('seleccionaun.label')}</h3>
            <ul>
              <li>
                <b>{t('excelente.label')}</b>
                850 - 781
              </li>
              <li>
                <b>{t('bueno.label')}</b>
                780 - 661
              </li>
              <li>
                <b>{t('regular.label')}</b>
                660 - 601
              </li>
              <li>
                <b>{t('malo.label')}</b>
                600 - 300
              </li>
            </ul>
            <button className='boton' type='submit' id="close-help-tip" value='continuar'>{t('entendido.label')}</button>
          </div>
        </div>
      </h2>
      <div className='form-st15 form_'>
        <div className='flx'>
          <select className='input' defaultValue={'DEFAULT'} onChange={setInput} name="credit_rating">
            <option value="DEFAULT" disabled>{t('calificaci.label')}</option>
            <option value="1">{t('bn.label')}</option>
            <option value="2">{t('regu.label')}</option>
            <option value="3">{t('mal.label')}</option>
            <option value="4">{t('nose.label')}</option>
          </select>
        </div>
      </div>
    </div>
  )
}