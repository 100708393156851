import React, { useState } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../../i18n'
import { useTranslation } from 'react-i18next'

export default function Step3({ dispathInputsBene, changeStep, setError }) {

  // eslint-disable-next-line
  const { t } = useTranslation()

  const [have, sethave] = useState(false)
  const [policies, setInputpolicies] = useState([
    {
      "id":1,
      "company": '',
      "nro_policy": '',
      "coverage": '',
      "date_afiliation_month": '',
      "date_afiliation_year": 0,
      "valid": ''
    }
  ])

  const setInput = (e, polici) => {
    $('body').find('.invalide').removeClass('invalide')
    setError(false)
    let local_policy = [...policies]
    local_policy.filter(function (data, i) {
      if (data.id === polici) {
        data[e.target.name] = e.target.value
      }
      return false
    });
    setInputpolicies(local_policy)
  }

  const ShowtheForm = () =>{
    $('.showForm').attr('style', 'display:block')
    $('.showForm').removeClass('animate__fadeOut')
    $('.showForm').addClass('animate__animated animate__fadeIn')
    sethave(true)
  }

  const ShowtheFormNo = () =>{
    $('.showForm').removeClass('animate__fadeIn')
    $('.showForm').addClass('animate__animated animate__fadeOut')
    sethave(false);
    setTimeout(() => {
      $('.showForm').attr('style', 'display:none')
    }, 300);
  }

  const submitInputs = () => {
    // console.log(policies);
    let validations = 0;

    if (have){
      policies.filter(function (data, i) {
        if ((data.company).trim() === '') {
          $('#comp-poliza').addClass('invalide')
          validations = 1;
        }
        if ((data.nro_policy).trim() === '') {
          $('#num-poliza').addClass('invalide')
          validations = 1;
        }
        if ((data.coverage).trim() === '') {
          $('#cant-poliza').addClass('invalide')
          validations = 1;
        }
        if ((data.date_afiliation_month).trim() === '') {
          $('.inp-mes-poli').addClass('invalide')
          validations = 1;
        }
        if ((data.date_afiliation_year) === 0 || (data.date_afiliation_year).length > 4 || (data.date_afiliation_year).length < 4 || isNaN(data.date_afiliation_year) || (data.date_afiliation_year).length === 0) {
          $('.inp-year-poli').addClass('invalide')
          validations = 1;
        }
        if ((data.valid).trim() === '') {
          $('.inp-vigent').addClass('invalide')
          validations = 1;
        }
      });
    }

    if (validations) {
      setError(true)
      return
    }
    dispathInputsBene({ 'policies': policies })
    changeStep(4)
  }

  return (
    <div className="step-content step-3-beneficiaries">
      <div className="backimage img23 img-3bene">
        <div className='img'>
          <img className='center' src={"images/img-step23.svg"} alt="img-step23" />
        </div>
      </div>
      <div className='contenido-step3'>
        <h2 className='center h2'>{t('tienespolisaanterior.label')}
          <div className="help-tip" role="tooltip" id="name-tooltip">
            <div className="help-tip__content st1-4">
              <p>
                {t('explicpoliant.label')}
              </p>
              <button className='boton' type='submit' id="close-help-tip" value='continuar'>{t('entendido.label')}</button>
            </div>
          </div>
        </h2>
        <div className='flx form-3-benef'>
          <div className='flx poliza-ant'>
            <button className=' center boton btn-3' type='submit' value='si-poliza-ant' onClick={ShowtheForm}>{t('si.label')}</button>
            <button className=' center boton btn-3' type='submit' value='no-poliza-ant' onClick={ShowtheFormNo}>{t('no.label')}</button>
          </div>
          <div className="showForm">
            {(policies.length > 0) && policies.map((poli, index) => 
              <div className='flx form-poliza' key={poli.id +  'polici' + index}>
                <div className='compañia-poliza info'>
                  <label className='label ' htmlFor="comp-poliza">
                    {t('compañy.label')}
                  </label>
                  <div className='flx'>
                    <input className='input' id='comp-poliza' type="text" name='company' onChange={(e) => setInput(e, poli.id)} />
                  </div>
                </div>
                <div className='numero-poliza info'>
                  <label className='label ' htmlFor="num-poliza">
                    {t('numpolizaant.label')}
                  </label>
                  <div className='flx'>
                      <input className='input' id='num-poliza' type="number" name='nro_policy' onChange={(e) => setInput(e, poli.id)} />
                  </div>
                </div>
                <div className='cantidad-poliza info'>
                  <label className='label ' htmlFor="cant-poliza">
                    {t('cobertpiliant.label')}
                  </label>
                  <div className='flx'>
                    <input className='input' id='cant-poliza' type="text" name='coverage' onChange={(e) => setInput(e, poli.id)} />
                  </div>
                </div>
                <div className='fecha-poliza'>
                  <label className=' label ' htmlFor="fecha-poliza">
                    {t('fechpoliant.label')}
                  </label>
                  <div className='flx'>
                    <select className='input a inp-mes-poli' name="date_afiliation_month" defaultValue={'DEFAULT'} onChange={(e) => setInput(e, poli.id)} >
                      <option value="DEFAULT" className='opt' disabled>{t('mes.label')}</option>
                      <option value="01"> {t('enero.label')}</option>
                      <option value="02"> {t('febrero.label')}</option>
                      <option value="03"> {t('marzo.label')}</option>
                      <option value="04"> {t('abril.label')}</option>
                      <option value="05"> {t('mayo.label')}</option>
                      <option value="06"> {t('junio.label')}</option>
                      <option value="07"> {t('julio.label')}</option>
                      <option value="08"> {t('agosto.label')}</option>
                      <option value="09"> {t('septiembre.label')}</option>
                      <option value="10"> {t('octubre.label')}</option>
                      <option value="11"> {t('noviembre.label')}</option>
                      <option value="12"> {t('diciembre.label')}</option>
                    </select>
                    <input className='input b inp-year-poli' type="text" name="date_afiliation_year" placeholder="Year" onChange={(e) => setInput(e, poli.id)} />
                  </div>
                </div>
                <div className='vigente-poliza'>
                  <label className=' label ' htmlFor="vig-poliza">
                    {t('vigentpoliant.label')}
                  </label>
                  <div className='flx vigente'>
                    <label className='checkbox label' htmlFor="si-vigente">
                      <input id='si-vigente' type="radio" name='valid' value='1' onClick={(e) => setInput(e, poli.id)} />
                      <span className="checkmark inp-vigent"></span>
                      {t('si.label')}
                    </label>
                    <label className='checkbox label' htmlFor="no-vigente">
                      <input id='no-vigente' type="radio" name='valid' value='0' onClick={(e) => setInput(e, poli.id)} />
                      <span className="checkmark inp-vigent"></span>
                      {t('no.label')}
                    </label>
                  </div>
                </div>
              </div>
            )}
            </div>
          <button className=' center boton btn-2' type='submit' value='continuar' onClick={submitInputs}>{t('continuar.label')}</button>
        </div>
      </div>
    </div>
  )
}