import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { debugContextDevtool } from 'react-context-devtool';

const container = document.getElementById('root'); 

let WithRouter = () => <BrowserRouter> <App /> </BrowserRouter>
ReactDOM.render(<WithRouter />, container);
reportWebVitals();

// Attach root container
debugContextDevtool(container);